import React, { useEffect, useState } from "react";
import axios from "axios";
import TitleBar from "../titleBar/TitleBar";
import "./smallcards.css";

const NewsGrid = () => {
  const [newsItems, setNewsItems] = useState([]);

  useEffect(() => {
    // API'den veriyi çekme
    axios.get("http://localhost:3000/news")
      .then(response => {
        // Veriyi sıralama ve son 9 haberi seçme
        const sortedPosts = response.data
          .filter(post => post.isHighlighted)
          .sort((a, b) => new Date(b.updateDate) - new Date(a.updateDate))
          .slice(0, 9);
        
        setNewsItems(sortedPosts);
      })
      .catch(error => {
        console.error("Error fetching news:", error);
      });
  }, []);

  return (
    <div className="news-grid-container">
      <TitleBar title="Öne Çıkan Haberler" /> <br />
      <div className="news-grid">
        {newsItems.length === 0 ? (
          <p>Öne çıkan haberlere ulaşılamadı.</p>
        ) : (
          newsItems.map((item) => (
            <div key={item.id} className="news-item w-100 h-100">
              <img src={item.image || "default-image-url.jpg"} alt={item.title} />
              <div className="news-content">
                <h3 className="title m-0">{item.title}</h3>
                {/* <p className="date">{new Date(item.updateDate).toLocaleDateString()}</p> */}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default NewsGrid;
