import React from "react";
import "./minCards.css";
import TitleBar from "../titleBar/TitleBar";

const ArticleList = () => {
  const articles = [
    {
      title: "MEHMET AVCI",
      category: "EKONOMİ",
      imageUrl:
        "https://www.ekonomimanset.com/wp-content/uploads/2022/10/image_750x_615b0260b7629.jpg",
    },
    {
      title: "MUZAFFER DEMİR",
      category: "EKONOMİ",
      imageUrl:
        "https://www.ekonomimanset.com/wp-content/uploads/2022/10/image_750x_61b1fcc61b4df-2.jpg",
    },
    {
      title: "ORHAN ERTÜRK",
      category: "EKONOMİ",
      imageUrl:
        "https://www.ekonomimanset.com/wp-content/uploads/2022/10/image_750x_6149129d4e7c0-4.jpg",
    },
    {
      title: "AZİZ HATMAN",
      category: "EKONOMİ",
      imageUrl:
        "https://ekonomimanset.com/wp-content/uploads/2024/03/business-lunch-is-Yemegi.jpg",
    },
  ];

  return (
    <div className="authors">
      <div className="col-span-1 p-[10px] lg:col-span-2 lg:p-0 author-content">
        <TitleBar title="YAZARLAR" />
      </div>
      <div id="mincards" className="article-list mincards mt-4">
          {articles.map((article, index) => (
            <div key={index} className="article-item">
              <div className="article-label">YAZAR</div>
              <img
                src={article.imageUrl}
                alt={article.title}
                className="article-image "
              />
              <div className="article-overlay">
                <div className="article-category">{article.title}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ArticleList;
