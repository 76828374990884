import { Menu, X, Twitter, Instagram } from "lucide-react";
import { useState, useEffect } from "react";
import ekonomiLogo from "../../assets/ekonomi-manset-logo.png";
import reklam1P from "../../assets/reklam.gif";
import "./navbar.css";
import React from "react";
import whiteLogo from "../../assets/white-logo.png";
import WeatherWidget from "./WeatherWidget";
import CurrencyWidget from "./CurrencyWidget";
import { useLocation, NavLink } from "react-router-dom";

const Navbar = () => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const toggleNavbar = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  const checkScroll = () => {
    if (!isScrolled && window.pageYOffset > 165) {
      setIsScrolled(true);
    } else if (isScrolled && window.pageYOffset <= 165) {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [isScrolled]);
  const [livestreamLink, setLivestreamLink] = useState("");

  useEffect(() => {
    const fetchLivestreamLink = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/livestreams`
        );
        if (!response.ok) {
          throw new Error("Veri alınamadı.");
        }
        const data = await response.json();
        if (data.length > 0) {
          setLivestreamLink(data[0].link);
        } else {
          console.error("Canlı yayın verisi bulunamadı.");
        }
      } catch (error) {
        console.error(
          "Canlı yayın verisi alınırken bir hata oluştu:",
          error.message
        );
      }
    };

    fetchLivestreamLink();
  }, []);

  const navItems = [
    { label: "KRİPTO PARA", href: "/kripto-para" },
    { label: "GÜNDEM", href: "/gundem" },
    { label: "REEL EKONOMİ", href: "/reel-ekonomi" },
    { label: "ÖZEL HABER", href: "/ozel-haber" },
    { label: "FİNANS", href: "/finans" },
    { label: "TEKNOLOJİ", href: "/teknoloji" },
    { label: "DÜNYA", href: "/dunya" },
    {
      label: "CANLI YAYIN",
      href: livestreamLink ? livestreamLink : "#",
      target: "_blank",
      rel: "noopener noreferrer",
    },
  ];

  const location = useLocation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setError("Şifreniz en az 8 karakter olmalıdır.");
      return;
    }
    if (password !== confirmPassword) {
      setError("Şifreler eşleşmiyor.");
      return;
    }
    if (!acceptedTerms) {
      setError("Kullanım sözleşmesini kabul etmelisiniz.");
      return;
    }

    console.log("Form gönderildi:", {
      firstName,
      lastName,
      username,
      email,
      password,
    });
  };

  const [query, setQuery] = useState("");

  const submitSearch = (event) => {
    event.preventDefault();
    window.location.href = `/search-results?q=${encodeURIComponent(query)}`;
  };

  return (
    <div className="nvb">
      <div className="bg-white-300 hidden w-full md:flex flex-col items-center relative">
        <div className="center-content">
          <a href="/" className="logo">
            <img src={ekonomiLogo} alt="psm-logo" className="" />
          </a>
          <img src={reklam1P} alt="reklam" className="advertisement" />
        </div>
        {/* <p className="h-[1px] text-center text-2xl font-bold text-black bottom-0 w-full"></p> */}
      </div>

      <nav
        className={`navbar-link-container  flex justify-between items-center h-[55px] w-full md:px-52  text-white ${
          isScrolled ? "fixed top-0 left-0 z-50 mb-14" : ""
        }`}
      >
        {/* Logo */}
        {/* <h1 className='w-full text-3xl font-bold text-[#00df9a]'>REACT.</h1> */}

        {/* Desktop Navigation */}
        <div className="flex h-full items-center">
          <div className="currency-widget-web flex justify-between items-center navbar-icon">
            <CurrencyWidget />
          </div>

          <ul className="hidden h-full md:flex items-center">
            <a
              href="/"
              className="navbar-link h-full flex items-center duration-200 navbar-home-button"
            >
              <i className="fa-solid fa-house"></i>
            </a>
            {navItems.map((item, index) => (
              <li
                key={index}
                className={`navbar-link h-full flex items-center cursor-pointer duration-200 ${
                  location.pathname === item.href ? "active" : ""
                }`}
              >
                <div className="lg:px-[8px] rounded-xl">
                  <a href={item.href} target={item.target}>
                    {item.label}
                  </a>
                </div>
              </li>
            ))}
            <div class="dropdown h-full flex items-center navbar-link flex items-center duration-200">
              <button
                class="btn other-icon"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                DİĞER <i class="fa-solid fa-chevron-down"></i>
              </button>
              <div class="dropdown-menu navbar-dropdown-container md:px-52 pt-6  shadow-2xl">
                <div className="grid grid-flow-col auto-cols-auto  text-slate-200 text-base font-medium leading-8  mobile-dropdown-container">
                  <div className="grid grid-rows-6 grid-flow-col responsive-column">
                    <h3 className="text-[#838383] font-bold text-lg">
                      HABERLER
                    </h3>
                    <a href="/weather" className="hover:text-gray-400">
                      Hava Durumu
                    </a>
                    {/* <a href="#" className="hover:text-gray-400">
                    Yayın Akışları
                  </a>

                  <a href="#" className="hover:text-gray-400">
                    Canlı Borsa
                  </a> */}
                    <a href="puanDurumu" className="hover:text-gray-400">
                      Puan Durumu
                    </a>
                    <a href="/traffic" className="hover:text-gray-400">
                      Yol Durumu
                    </a>
                  </div>
                  <div className="grid grid-rows-6 grid-flow-col responsive-column">
                    <h1 className="text-[#838383] font-bold  text-lg">
                      SERVİSLER
                    </h1>
                    <a href="/canli-tv" className="hover:text-gray-400">
                      Canlı TV
                    </a>

                    <a href="/pharmacy" className="hover:text-gray-400">
                      Nöbetçi Eczaneler
                    </a>
                    <a href="/namaz" className="hover:text-gray-400">
                      Namaz Vakitleri
                    </a>

                    {/* <a href="#" className="hover:text-gray-400">
                    Son Dakika
                  </a> */}
                  </div>

                  <div className="grid grid-rows-6 grid-flow-col responsive-column">
                    <h1 className="text-[#838383] font-bold text-lg">FİNANS</h1>
                    <a href="/altin" className="hover:text-gray-400">
                      Altınlar
                    </a>
                    <a href="/kur" className="hover:text-gray-400">
                      Dövizler
                    </a>
                    <a href="/hisse" className="hover:text-gray-400">
                      Hisseler
                    </a>
                    <a href="/kripto" className="hover:text-gray-400">
                      Kripto Paralar
                    </a>
                    <a href="/parite" className="hover:text-gray-400">
                      Pariteler
                    </a>
                  </div>
                  {/* <div className="grid grid-rows-6 grid-flow-col responsive-column">
                  <h1 className="text-[#838383] font-bold text-base">
                    İNTERAKTİF
                  </h1>
                  <a href="#" className="hover:text-gray-400">
                    Foto Galeri
                  </a>
                  <a href="#" className="hover:text-gray-400">
                    Video Galeri
                  </a>
                  <a href="#" className="hover:text-gray-400">
                    Gazeteler
                  </a>
                  <a href="#" className="hover:text-gray-400">
                    Sıcak Haber
                  </a>
                </div> */}
                  <div className="grid grid-rows-6 grid-flow-col responsive-column">
                    <h1 className="text-[#838383] font-bold text-lg">HESAP</h1>
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#login-modal"
                      className="hover:text-gray-400 cursor-pointer"
                    >
                      Giriş Yap
                    </a>
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#login-modal"
                      className="hover:text-gray-400 cursor-pointer"
                    >
                      Üye Kayıt
                    </a>
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#login-modal"
                      className="hover:text-gray-400 cursor-pointer"
                    >
                      Şifremi Unuttum
                    </a>
                    <a href="/profile" className="hover:text-gray-400">
                      Hesap Ayarları
                    </a>
                  </div>
                  <div className="grid grid-rows-6 grid-flow-col responsive-column">
                    <h1 className="text-[#838383] font-bold text-lg">DİĞER</h1>
                    <a href="/contact" className="hover:text-gray-400">
                      İletişim
                    </a>
                    <a href="/kunye" className="hover:text-gray-400">
                      Künye
                    </a>
                    <a href="/about" className="hover:text-gray-400">
                      Hakkımızda
                    </a>
                    {/* <a href="#" className="hover:text-gray-400">
                    Reklam
                  </a> */}
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
        {/* Mobile Navigation Icon */}
        <div className="md:hidden mx-4">
          <a href="/" className="logo">
            <img src={whiteLogo} alt="psm-logo" className="mobile-logo" />
          </a>
        </div>
        <div className="block ml-6 flex items-center md:hidden">
          <button onClick={toggleNavbar}>
            {mobileDrawerOpen ? <X /> : <Menu />}
          </button>
        </div>
        {/* Mobile Navigation Menu */}
        <ul
          className={
            mobileDrawerOpen
              ? "fixed z-50 md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#18181B] ease-in-out duration-500"
              : "ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]"
          }
        >
          {/* Mobile Logo */}
          <div className=" h-[50px] mt-2 md:hidden mx-auto">
            <a href="/" className="logo">
              <img
                src={whiteLogo}
                alt="psm-logo"
                className=" h-auto mobile-logo px-8"
              />
            </a>
          </div>
          {/* Mobile Navigation Items */}
          {navItems.map((item, index) => (
            <li
              key={index}
              className="p-4 border-b  hover:bg-[#F73C29] font-semibold duration-300 hover:text-black cursor-pointer border-gray-600"
            >
              <a href={item.href}>{item.label}</a>
            </li>
          ))}
          <div className="absolute bottom-3 text-3xl left-3 flex gap-3">
            <a
              href="https://twitter.com/manset_ekonomi"
              className="hover:text-[#50ABF1]"
              target="_blank"
              rel="noreferrer"
            >
              <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                <i class="fa-brands fa-x-twitter"></i>
              </div>
            </a>
            <a
              href="https://www.instagram.com/ekonomimanset/"
              className="hover:text-[#FE1D80]"
              target="_blank"
              rel="noreferrer"
            >
              <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                <i class="fa-brands fa-instagram"></i>
              </div>
            </a>
            <a
              href="https://www.youtube.com/@psmdergisi9390"
              className="hover:text-[#FF0000]"
              target="_blank"
              rel="noreferrer"
            >
              <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                <i class="fa-brands fa-youtube"></i>
              </div>
            </a>
            <a
              href="https://www.linkedin.com/company/ekonomi%CC%87-man%C5%9Fet/"
              className="hover:text-[#0274B3]"
              target="_blank"
              rel="noreferrer"
            >
              <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                <i class="fa-brands fa-linkedin-in"></i>
              </div>
            </a>
            <a
              href="https://wa.me/905324627687"
              className="hover:text-[#26C943]"
              target="_blank"
              rel="noreferrer"
            >
              <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                <i class="fa-brands fa-whatsapp"></i>
              </div>
            </a>
          </div>
        </ul>

        <div className="inline-flex h-[92%]">
          <div class="btn-group h-full">
            <button
              type="button"
              class="btn navbar-icon"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa-solid fa-magnifying-glass "></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end search-dropdown">
              <form onSubmit={submitSearch} className="d-flex items-center">
                <input
                  className="search-input md:w-[400px]"
                  placeholder="Ne aramak istersiniz?"
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <input
                  className="text-white bg-neutral-600 p-2 rounded-lg hover:bg-neutral-800"
                  type="submit"
                  value="Ara"
                />
              </form>
            </div>
          </div>

          <button
            type="button"
            class="btn navbar-icon"
            data-bs-toggle="modal"
            data-bs-target="#login-modal"
          >
            <i class="fa-regular fa-user"></i>
          </button>
          <div
            class="modal fade"
            id="login-modal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-fullscreen-md-down">
              <div class="modal-content border-0 rounded-md text-white login-modal-content">
                <div class="modal-header text-left">
                  <ul class="nav nav-underline" id="myTab" role="tablist">
                    <li
                      class="nav-item login-modal-nav-item"
                      role="presentation"
                    >
                      <button
                        class="nav-link active"
                        id="login-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#login-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="login-tab-pane"
                        aria-selected="true"
                      >
                        Giriş Yap
                      </button>
                    </li>
                    <li
                      class="nav-item login-modal-nav-item"
                      role="presentation"
                    >
                      <button
                        class="nav-link"
                        id="sign-up-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#sign-up-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="sign-up-tab-pane"
                        aria-selected="false"
                      >
                        Kayıt Ol
                      </button>
                    </li>
                    <li
                      class="nav-item login-modal-nav-item"
                      role="presentation"
                    >
                      <button
                        class="nav-link"
                        id="password-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#password-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="password-tab-pane"
                        aria-selected="false"
                      >
                        Şifremi Unuttum
                      </button>
                    </li>
                  </ul>

                  <button
                    type="button"
                    class="btn-close login-modal-close-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div class="modal-body text-left px-12 login-modal-body">
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="login-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabindex="0"
                    >
                      <form className="leading-8">
                        <label for="username ">
                          Kullanıcı Adınız veya E-posta Adresiniz
                        </label>
                        <br />
                        <input
                          type="text"
                          id="username"
                          name="username"
                          className="login-modal-input"
                          required
                        />
                        <br />
                        <label for="password">Şifreniz</label>
                        <br />
                        <input
                          type="password"
                          id="password"
                          name="password"
                          className="login-modal-input"
                          required
                        />
                        <br />

                        <div className="flex mt-2 justify-between items-center">
                          <div class="form-check flex items-center">
                            <input
                              class="form-check-input login-modal-check"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />{" "}
                            &nbsp; &nbsp;
                            <label
                              class="form-check-label text-base"
                              for="flexCheckDefault"
                            >
                              Beni Hatırla
                            </label>
                          </div>
                          <input
                            className="login-modal-submit-btn"
                            type="submit"
                            value="Giriş Yap"
                          />
                        </div>
                      </form>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="sign-up-tab-pane"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      tabindex="0"
                    >
                      <form onSubmit={handleSubmit} className="leading-8">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <label htmlFor="first-name">Adınız</label>
                            <br />
                            <input
                              type="text"
                              id="first-name"
                              name="first-name"
                              className="login-modal-input"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              required
                            />
                          </div>
                          <div>
                            <label htmlFor="last-name">Soyadınız</label>
                            <br />
                            <input
                              type="text"
                              id="last-name"
                              name="last-name"
                              className="login-modal-input"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <label htmlFor="username">Kullanıcı Adınız</label>
                        <br />
                        <input
                          type="text"
                          id="username"
                          name="username"
                          className="login-modal-input"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          required
                        />
                        <br />
                        <label htmlFor="email">E-posta Adresiniz</label>
                        <br />
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="login-modal-input"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <br />
                        <label htmlFor="password">Şifreniz</label>
                        <br />
                        <input
                          type="password"
                          id="password"
                          name="password"
                          className="login-modal-input"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <br />
                        <label htmlFor="confirm-password">
                          Şifreniz (Tekrar)
                        </label>
                        <br />
                        <input
                          type="password"
                          id="confirm-password"
                          name="confirm-password"
                          className="login-modal-input"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                        <br />
                        {error && <p style={{ color: "red" }}>{error}</p>}

                        <div className="form-check flex items-center">
                          <input
                            className="form-check-input login-modal-check"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            checked={acceptedTerms}
                            onChange={() => setAcceptedTerms(!acceptedTerms)}
                          />
                          &nbsp; &nbsp;
                          <label
                            className="form-check-label text-base"
                            htmlFor="flexCheckDefault"
                          >
                            <a href="" className="text-red-500 font-semibold">
                              Kullanım sözleşmesini
                            </a>{" "}
                            okudum ve kabul ediyorum.
                          </label>
                        </div>

                        <input
                          className="login-modal-submit-btn"
                          type="submit"
                          value="Üye Kaydını Tamamla"
                        />
                      </form>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="password-tab-pane"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                      tabindex="0"
                    >
                      <form action="">
                        <label htmlFor="email">E-posta Adresiniz</label>
                        <br />
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="login-modal-input"
                          required
                        />
                        <br />

                        <input
                          className="login-modal-submit-btn"
                          type="submit"
                          value="Devam Et"
                        />
                      </form>

                      <p className="text-neutral-300 text-sm">
                        Size yeni şifre oluşturabileceğiniz bir e-posta
                        göndereceğiz. Gönderdiğimiz e-posta bir kaç dakika
                        içerisinde ulaşmazsa e-posta sağlayıcınızın spam
                        bölümünü kontrol etmeyi unutmayın.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="lg:hidden dropdown h-[97%] flex items-center">
            <button
              class="btn navbar-icon"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa-solid fa-chevron-down"></i>
            </button>
            <div class="dropdown-menu navbar-dropdown-container md:px-52 pt-6  shadow-2xl">
              <div className="grid grid-flow-col auto-cols-auto  text-slate-200 text-base font-medium leading-8  mobile-dropdown-container">
                <div className="grid grid-rows-6 grid-flow-col responsive-column">
                  <h3 className="text-[#838383] font-bold text-lg">HABERLER</h3>
                  <a href="#" className="hover:text-gray-400">
                    Hava Durumu
                  </a>
                  {/* <a href="#" className="hover:text-gray-400">
                    Yayın Akışları
                  </a> */}

                  {/* <a href="#" className="hover:text-gray-400">
                    Canlı Borsa
                  </a> */}
                  <a href="puanDurumu" className="hover:text-gray-400">
                    Puan Durumu
                  </a>
                </div>
                <div className="grid grid-rows-6 grid-flow-col responsive-column">
                  <h1 className="text-[#838383] font-bold  text-lg">
                    SERVİSLER
                  </h1>
                  <a href="/canli-tv" className="hover:text-gray-400">
                    Canlı TV
                  </a>
                  <a href="/traffic" className="hover:text-gray-400">
                    Yol Durumu
                  </a>
                  <a href="/pharmacy" className="hover:text-gray-400">
                    Nöbetçi Eczaneler
                  </a>
                  <a href="/namaz" className="hover:text-gray-400">
                    Namaz Vakitleri
                  </a>

                  {/* <a href="#" className="hover:text-gray-400">
                    Son Dakika
                  </a> */}
                </div>

                <div className="grid grid-rows-6 grid-flow-col responsive-column">
                  <h1 className="text-[#838383] font-bold text-lg">FİNANS</h1>
                  <a href="/altin" className="hover:text-gray-400">
                    Altınlar
                  </a>
                  <a href="/kur" className="hover:text-gray-400">
                    Dövizler
                  </a>
                  <a href="/hisse" className="hover:text-gray-400">
                    Hisseler
                  </a>
                  <a href="/kripto" className="hover:text-gray-400">
                    Kripto Paralar
                  </a>
                  <a href="/parite" className="hover:text-gray-400">
                    Pariteler
                  </a>
                </div>
                {/* <div className="grid grid-rows-6 grid-flow-col responsive-column">
                  <h1 className="text-[#838383] font-bold text-base">
                    İNTERAKTİF
                  </h1>
                  <a href="#" className="hover:text-gray-400">
                    Foto Galeri
                  </a>
                  <a href="#" className="hover:text-gray-400">
                    Video Galeri
                  </a>
                  <a href="#" className="hover:text-gray-400">
                    Gazeteler
                  </a>
                  <a href="#" className="hover:text-gray-400">
                    Sıcak Haber
                  </a>
                </div> */}
                <div className="grid grid-rows-6 grid-flow-col responsive-column">
                  <h1 className="text-[#838383] font-bold text-lg">HESAP</h1>
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#login-modal"
                    className="hover:text-gray-400 cursor-pointer"
                  >
                    Giriş Yap
                  </a>
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#login-modal"
                    className="hover:text-gray-400 cursor-pointer"
                  >
                    Üye Kayıt
                  </a>
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#login-modal"
                    className="hover:text-gray-400 cursor-pointer"
                  >
                    Şifremi Unuttum
                  </a>
                  <a href="/profile" className="hover:text-gray-400">
                    Hesap Ayarları
                  </a>
                </div>
                <div className="grid grid-rows-6 grid-flow-col responsive-column">
                  <h1 className="text-[#838383] font-bold text-lg">DİĞER</h1>
                  <a href="/contact" className="hover:text-gray-400">
                    İletişim
                  </a>
                  <a href="/kunye" className="hover:text-gray-400">
                    Künye
                  </a>
                  <a href="/about" className="hover:text-gray-400">
                    Hakkımızda
                  </a>
                  {/* <a href="#" className="hover:text-gray-400">
                    Reklam
                  </a> */}
                </div>
              </div>
            </div>
          </div>

          <div className="navbar-weather-web flex items-center">
            <WeatherWidget />
          </div>
        </div>
      </nav>

      <div className="mobile-currency-weather lg:hidden grid grid-cols-2 justify-between items-center">
        <CurrencyWidget />
        <WeatherWidget />
      </div>

      {/* Advertisement for mobile */}
      <div className="md:hidden">
        <img src={reklam1P} alt="reklam" className="advertisement" />
      </div>

      <div className={`navbar.content ${isScrolled ? "h-16" : ""}`}></div>
    </div>
  );
};

export default Navbar;
