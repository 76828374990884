import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Kunye from "./pages/kunye/KunyePage";
import AnimatedText from "../src/components/animatedText/AnimatedText";
// import Carousel from "./components/carousel/carousel";
import AdsLeft from "../src/components/ads/AdsLeft";
import AdsRight from "../src/components/ads/AdsRights";
import Footer from "./components/footer/Footer";
import TopButton from "./components/topButton/TopButton";
import ContentPage from "./pages/content/ContentPage";
import ContactPage from "./pages/contact/ContactPage";
import About from "./pages/about/About";
import PuanDurumu from "./components/puanDurumu/puanDurumu";
import ProfilePage from "./pages/profile/ProfilePage";
import PrayerTime from "./components/namaz/PrayerTime";
import Altin from "./components/altin/altin";
import Kur from "./components/kurDegisim/kur";
import TrafficInfo from "./components/traffic/TrafficInfo";
import PharmacyPage from "./components/pharmacy/PharmacyPage";
import CryptoCategory from "./pages/category/CryptoCategory";
import DailyCategory from "./pages/category/DailyCategory";
import SpecialCategory from "./pages/category/SpecialPage";
import EconomyCategory from "./pages/category/EconomyPage";
import FinanceCategory from "./pages/category/FinancePage";
import TechnologyCategory from "./pages/category/TechnologyPage";
import WorldCategory from "./pages/category/WorldPage";
import Kripto from "./components/kripto/kripto";
import Hisse from "./components/hisse/hisse";
import SearchPage from "./pages/search/SearchPage";
import LiveTv from "./components/liveTv/LiveTv";
import Parite from "./components/parite/parite";
import Weather from "./components/weatherPage/weather";
import AdsMobile from "./components/ads/AdsMobile";
import AdsDownPopup from "./components/ads/adsDownPopup";


function App() {
  return (
    <div className="App">
      <Router>
        <AnimatedText />
        <Navbar />
        <AdsMobile />
        <AdsLeft />
        <AdsRight />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/content" element={<ContentPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/kripto-para" element={<CryptoCategory />} />
          <Route path="/gundem" element={<DailyCategory />} />
          <Route path="/reel-ekonomi" element={<EconomyCategory />} />
          <Route path="/ozel-haber" element={<SpecialCategory />} />
          <Route path="/finans" element={<FinanceCategory />} />
          <Route path="/teknoloji" element={<TechnologyCategory />} />
          <Route path="/dunya" element={<WorldCategory />} />       
          <Route path="/Kunye" element={<Kunye />} />
          <Route path="/puanDurumu" element={<PuanDurumu/>}/>
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/namaz" element={<PrayerTime />} />
          <Route path="/Altin" element={<Altin/>}/>
          <Route path="/Kur" element={<Kur/>}/>
          <Route path="/traffic" element={<TrafficInfo/>}/>
          <Route path="/pharmacy" element={<PharmacyPage />}/>
          <Route path="/kripto" element={<Kripto/>}/>
          <Route path="/hisse" element={<Hisse/>}/>
          <Route path="/search-results" element={<SearchPage/>}/>
          <Route path="/canli-tv" element={<LiveTv />}/>
          <Route path="/parite" element={<Parite />}/>
          <Route path="/weather" element={<Weather />}/>
        </Routes>
        <AdsDownPopup />
        <TopButton />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
