import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CategoryLinks = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // API'den kategorileri çekme
    axios.get('http://localhost:3000/api/categories')
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  return (
    <div className="flex flex-wrap gap-4 mt-4">
      {categories.map((category, index) => (
        <a
          key={category._id} // Öğe için unique bir anahtar olarak _id kullanılabilir
          href={`/category/${category.name}`} // Dinamik olarak kategori ismi ile URL oluşturuluyor
          className={`p-2.5 text-base rounded-lg hover:scale-110 ${
            index % 6 === 0 ? 'bg-[#57c4ff31]' :
            index % 6 === 1 ? 'bg-[#da85c731]' :
            index % 6 === 2 ? 'bg-[#7fb88133]' :
            index % 6 === 3 ? 'bg-[#ff795736]' :
            index % 6 === 4 ? 'bg-[#ffb04f45]' :
            'bg-[#5e4fff31]'
          }`}
        >
          {category.name} {/* Kategori ismini göstermek için category.name kullanılıyor */}
        </a>
      ))}
    </div>
  );
};

export default CategoryLinks;
