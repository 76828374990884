import React, { useState, useEffect } from "react";
import "./pharmacyPage.css";
import axios from "axios";
import citiesData from "./pharmacyCities.json"; // Şehir verilerini içe aktarın
import eczaneLogo from "./eczane-logo.png"

const PharmacyPage = () => {
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedDistrict, setSelectedDistrict] = useState("");
    const [pharmacies, setPharmacies] = useState([]);
    const [currentDate, setCurrentDate] = useState("");

    useEffect(() => {
        setCities(citiesData);
    }, []);

    useEffect(() => {
        if (selectedCity) {
            const selectedCityObject = cities.find(city => city.name === selectedCity);
            setDistricts(selectedCityObject ? selectedCityObject.districts : []);
            setSelectedDistrict(""); 
            setPharmacies([]); // 
        }
    }, [selectedCity, cities]);

    useEffect(() => {
        const fetchPharmacies = async () => {
            try {
                if (selectedCity && selectedDistrict) {
                    const response = await axios.get(
                        `https://api.collectapi.com/health/dutyPharmacy?il=${selectedCity}&ilce=${selectedDistrict}`,
                        {
                            headers: {
                                // "authorization": "apikey 3wQi5ac6HX4kcuPtrs6hLm:7jNyCIuGO83HxCUl3N6Zl9",
                                "content-type": "application/json",
                            },
                        }
                    );
                    if (response.data && response.data.result) {
                        setPharmacies(response.data.result);
                    } else {
                        setPharmacies([]);
                    }
                }
            } catch (error) {
                console.error("Error fetching pharmacies:", error);
                setPharmacies([]); 
            }
        };

        fetchPharmacies();
    }, [selectedCity, selectedDistrict]);

    useEffect(() => {
        const months = [
            "Ocak",
            "Şubat",
            "Mart",
            "Nisan",
            "Mayıs",
            "Haziran",
            "Temmuz",
            "Ağustos",
            "Eylül",
            "Ekim",
            "Kasım",
            "Aralık",
        ];
        const today = new Date();
        const formattedDate = `${today.getDate()} ${months[today.getMonth()]} ${today.getFullYear()}`;
        setCurrentDate(formattedDate);
    }, []);

    return (
        <div className="pharmacy-page-container px-60">

            <div className="pharmacy-div-container  text-left px-8 py-8">

                <div className="flex gap-4 items-center">
                    <img className="h-[100px]" src={eczaneLogo} alt="" />
                    <div className="font-semibold text-[#E61417] pharmacy-div-title">
                        <p className="lg:text-5xl">{currentDate}</p>
                        <h1 className="lg:text-4xl">Nöbetçi Eczaneler</h1>
                    </div>
                </div>



                <div className="lg:grid grid-cols-2 gap-4  mt-6">

                    <div className="grid grid-rows-2">

                        <div className="pharmacy-page-input">
                            <label className="mb-2 font-semibold text-xl" htmlFor="city">Şehir Seçiniz</label>
                            <div className="pharmacy-city-list-container">
                                <div className="pharmacy-city-list">
                                    {cities.map((city) => (
                                        <div
                                            key={city.name}
                                            className={`pharmacy-city-item ${selectedCity === city.name ? "active" : ""}`}
                                            onClick={() => setSelectedCity(city.name)}
                                        >
                                            {city.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="pharmacy-page-input">
                            <label className="mb-2 font-semibold text-xl" htmlFor="district">İlçe Seçiniz</label>
                            <div className="pharmacy-district-list-container">
                                <div className="pharmacy-district-list">
                                    {districts.map((district, index) => (
                                        <div
                                            key={index}
                                            className={`pharmacy-district-item ${selectedDistrict === district ? "active" : ""}`}
                                            onClick={() => setSelectedDistrict(district)}
                                        >
                                            {district}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>


                    <div>
                        <h1 className="pharmacy-page-title">Nöbetçi Eczaneler Listesi</h1>
                        <div className="pharmacy-list">
                            {!Array.isArray(pharmacies) || pharmacies.length === 0 ? (
                                <p>Nöbetçi eczane bulunamadı.</p>
                            ) : (
                                <ul className="list-disc pl-4">
                                    {pharmacies.map((pharmacy, index) => (
                                        <li className="mb-4" key={index}>
                                            <b>{pharmacy.name}</b>  - {pharmacy.address} - {pharmacy.phone}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PharmacyPage;
