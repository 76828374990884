import React, { useState, useEffect } from "react";
import "./contentPage.css";
import SideMenu from "../../components/sideMenu/SideMenu";
import CommentSection from "../../components/comments/CommentSection";
import axios from "axios";

const ContentPage = () => {
  const [newsData, setNewsData] = useState({});
  const [newsItems, setNewsItems] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://news-api-gexw.onrender.com/news`
        );
        const { data } = response;
        console.log("apiurl", apiUrl);
        const newsData = data.find((item) => item.slug === "altinda-son-durum");

        if (newsData) {
          setNewsData(newsData);
          setNewsItems(newsData.recommendedNews || []);
        }
      } catch (error) {
        console.error("Veri getirilirken hata oluştu:", error);
      }
    };

    fetchData();
  }, [apiUrl]);

  // Function to format news text into paragraphs
  const formatNewsText = (text) => {
    return text
      ? text.split("\n\n").map((paragraph, index) => (
          <p key={index}>
            {paragraph.split("\n").map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
        ))
      : null;
  };

  const shareUrl = window.location.href;

  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const shareOnTwitter = () => {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const shareOnLinkedIn = () => {
    const url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const shareOnWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="content-page-container">
      <div className="page-link-container md:px-60 text-left text-base mt-6 font-medium">
        <a href="#" className="hover:underline">
          Ekonomi Manşet
        </a>
        <i className="fa-solid fa-chevron-right mx-2 text-xs"></i>
        <a href="#" className="hover:underline">
          {newsData.categories &&
            newsData.categories.length > 0 &&
            newsData.categories[0]}
        </a>
        <i className="fa-solid fa-chevron-right mx-2 text-xs"></i>
        <a href="#" className="hover:underline">
          {newsData.title}
        </a>
      </div>

      <div className="content-page-header md:px-60 py-6">
        <h1 className="text-4xl font-bold mb-3">{newsData.title}</h1>
        <p className="text-base mb-2">{newsData.summary}</p>
        <div className="flex items-center text-gray-600">
          <div>
            <p className="text-xs">
              {newsData.updateDate &&
                new Date(newsData.updateDate).toLocaleString()}{" "}
              • {newsData.commentCount} Yorum{" "}
              <i className="fa-regular fa-comment"></i> • {newsData.viewCount}{" "}
              <i className="fa-solid fa-eye"></i>
            </p>
          </div>
        </div>
      </div>

      <div className="content-container md:grid grid-cols-11 md:px-60">
        <div className="content-left-container col-span-7 md:pr-[30px]">
          <img src={newsData.image} alt="" className="content-image" />
          <div>{formatNewsText(newsData.newsText)}</div>

          <CommentSection />

          <div className="share-card-container text-white mt-8 ">
            <div className="h-14 bg-[#101010] pl-6 flex items-center rounded-t-xl">
              <i className="fa-solid fa-share-nodes mr-4"></i>
              <span className="font-bold mr-6">PAYLAŞ</span>

              <div className="share-link-container text-lg flex gap-2">
                <a onClick={shareOnFacebook} className="hover:bg-[#0867FF] hover:border-[#0867FF] cursor-pointer">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a onClick={shareOnTwitter} className="hover:bg-[#50ABF1] hover:border-[#50ABF1] cursor-pointer">
                  <i className="fa-brands fa-x-twitter"></i>
                </a>
                <a onClick={shareOnLinkedIn} className="hover:bg-[#0274B3] hover:border-[#0274B3] cursor-pointer">
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
                <a onClick={shareOnWhatsApp} className="hover:bg-[#26CA43] hover:border-[#26CA43] cursor-pointer">
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
              </div>
            </div>
            <div className="h-14 bg-[#282828] pl-6 flex items-center">
              <i className="fa-solid fa-tags mr-4"></i>{" "}
              <span>
                {newsData.tags &&
                  newsData.tags.map((tag, index) => (
                    <React.Fragment key={index}>
                      <a href="#" className="hover:underline">
                        {tag}
                      </a>
                      {index < newsData.tags.length - 1 && ", "}
                    </React.Fragment>
                  ))}
              </span>
            </div>

            <div className="h-24 bg-[#373737] px-6 py-3 grid grid-cols-2">
              <div className="text-left pr-6 border-r border-neutral-600">
                <a href="#">
                  <i className="fa-solid fa-arrow-left"></i>
                  <span className="next-previous-content md:mx-4 text-neutral-400">
                    Önceki Haber
                  </span>
                  <h4 className="text-sm mt-2 truncate ">
                    10 soruda "FATF" ve Türkiye'nin "gri liste"den çıkışı
                  </h4>
                </a>
              </div>
              <div className="text-right pl-6">
                <a href="#">
                  <span className="next-previous-content md:mx-4 text-neutral-400">
                    Sonraki Haber
                  </span>
                  <i className="fa-solid fa-arrow-right"></i>
                  <h4 className="text-sm mt-2 truncate">
                    CHP lideri Özel'in kardeşi Barış Özel'den İzmir'de suya zam
                    tepkisi
                  </h4>
                </a>
              </div>
            </div>
            <div className="bg-[#F5F5F5] text-[#373737] pt-10 px-8 rounded-b-xl">
              <h3 className="text-lg font-semibold">ÖNERİLEN HABERLER</h3>
              <div className="pb-2">
                <div className="news-grid-container">
                  <div className="news-grid">
                    {newsItems.map((item) => (
                      <div key={item.id} className="news-item">
                        <img src={item.image} alt={item.title} />
                        <div className="news-content">
                          <p className="date">{item.date}</p>
                          <h3 className="title">{item.title}</h3>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content-right-container col-span-4 md:pl-[30px]">
          <SideMenu />
        </div>
      </div>
    </div>
  );
};

export default ContentPage;
