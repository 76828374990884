import React from 'react'
import "./footer.css";
import whiteLogo from "../../assets/white-logo.png";
import alohaWhiteLogo from "./aloha-white-logo.png";

const Footer = () => {
  return (
    <div>
      
      <footer className='footer-container  pt-8'>
        <div className='footer-top-container md:pl-52 md:flex gap-4'>
          <div className='footer-top-left-container md:w-80'>
            <img src={whiteLogo} alt="" className='w-60 ' />
          </div>
          <div className='footer-top-right-container text-white md:flex items-center text-left'>
            <span className='mr-8 '>Haberleri güncel olarak e-postanızdan takip edebilirsiniz!</span>
            <input type="email" placeholder='E-posta adresiniz' className='footer-email-input' />
            <button className='footer-email-button'><i class="fa-solid fa-circle-arrow-right"></i></button>
          </div>
        </div>

        <div className='footer-mid-container md:pl-52 md:pr-24  md:flex md:mt-6 gap-4'>

          <div className='footer-mid-left-container md:w-80 mb-2 leading-9 break-words'>

            <div className='grid grid-cols-6 gap-x-2 text-left text-gray-200'>
              <div>
                <i class="fa-solid fa-phone footer-icon"></i>
              </div>
              <div className='col-span-5 text-left text-gray-400'><span>+90 216 550 10 61 / 62</span></div>
            </div>

            <div className='grid grid-cols-6 gap-x-2 text-left text-gray-200'>
              <div>
                <i class="fa-solid fa-envelope"></i>
              </div>
              <div className='col-span-5 text-left text-gray-400'><span>bbekar@akilliyasamdergisi.com</span></div>
            </div>

            <div className='grid grid-cols-6 gap-x-2 text-left text-gray-200'>
              <div>
                <i class="fa-solid fa-location-dot"></i>
              </div>
              <div className='col-span-5 text-left text-gray-400'>Osmanağa Mah. Hasırcıbaşı Cad. Hasırcıbaşı Apt. No:15/3 Kadıköy/İstanbul</div>
            </div>



          </div>

          <div className='footer-mid-right-container md:grid grid-flow-col auto-cols-max gap-14 text-gray-200 text-left leading-9'>

            <div className='md:grid grid-flow-row auto-rows-max'>

              <div><a href="/contact" className='hover:text-gray-500'>İletişim</a></div>
              <div><a href="/kunye" className='hover:text-gray-500'>Künye</a></div>
              <div><a href="/about" className='hover:text-gray-500'>Hakkımızda</a></div>
              <div><a href="#" className='hover:text-gray-500'>Reklam</a></div>

            </div>
            <hr className='md:hidden my-2' />
            <div className='md:grid grid-flow-row auto-rows-max social-media-container'>
              <div className='md:mb-2 font-semibold'>BİZİ TAKİP EDİN</div>
              <div className='grid grid-flow-col auto-cols-max gap-3 text-gray-200 text-2xl text-left leading-9 '>
                <div>
                  <a href="https://twitter.com/manset_ekonomi" className='hover:text-[#50ABF1]' target="_blank" rel="noreferrer">
                    <div className=' grid grid-flow-col auto-cols-max items-center gap-3'>
                    <i class="fa-brands fa-x-twitter"></i>

                    </div>
                  </a>
                </div>

                <div>
                  <a href="https://www.instagram.com/ekonomimanset/" className='hover:text-[#FE1D80]' target="_blank" rel="noreferrer"><div className=' grid grid-flow-col auto-cols-max items-center gap-3'>
                  <i class="fa-brands fa-instagram"></i>

                  </div></a>
                </div>

                <div>
              <a href="https://www.youtube.com/@psmdergisi9390" className='hover:text-[#FF0000]' target="_blank" rel="noreferrer"><div className=' grid grid-flow-col auto-cols-max items-center gap-3'>
                <i class="fa-brands fa-youtube"></i>
              </div></a>
            </div>

                <div>
                  <a href="https://www.linkedin.com/company/ekonomi%CC%87-man%C5%9Fet/" className='hover:text-[#0274B3]' target="_blank" rel="noreferrer"><div className=' grid grid-flow-col auto-cols-max items-center gap-3'>
                  <i class="fa-brands fa-linkedin-in"></i>
                  </div></a>
                </div>
                <div>
                  <a href="https://wa.me/905324627687" className='hover:text-[#26C943]' target="_blank" rel="noreferrer"><div className=' grid grid-flow-col auto-cols-max items-center gap-3'>
                  <i class="fa-brands fa-whatsapp"></i>
                  </div></a>
                </div>

              </div>
            </div>

          </div>

          <hr className='md:hidden my-2 text-gray-200' />

          <div className='footer-mid-left-container-mobile md:hidden md:w-80 mb-2 leading-9 break-normal'>
            
            <div className='grid grid-cols-9 gap-x-2 text-left text-gray-200'>
              <div>
                <i class="fa-solid fa-phone footer-icon"></i>
              </div>
              <div className='col-span-8 text-left text-gray-400'><span>+90 216 550 10 61 / 62</span></div>
            </div>
            <div className='grid grid-cols-9 gap-x-2 text-left text-gray-200'>
              <div>
                <i class="fa-solid fa-envelope"></i>
              </div>
              <div className='col-span-8 text-left text-gray-400'><span>bbekar@akilliyasamdergisi.com</span></div>
            </div>

            <div className='grid grid-cols-9 gap-x-2 text-left text-gray-200'>
              <div>
                <i class="fa-solid fa-location-dot"></i>
              </div>
              <div className='col-span-8 text-left text-gray-400'>Osmanağa Mah. Hasırcıbaşı Cad. Hasırcıbaşı Apt. No:15/3 Kadıköy/İstanbul</div>
            </div>
          </div>

        </div>


        <div className='footer-bottom-container bg-zinc-900 h-14 md:mt-8 flex justify-center items-center'>
          <a href="https://www.alohadijital.com/" target="_blank"><img className='h-[30px] mx-2' src={alohaWhiteLogo} alt="" /></a><span className='text-neutral-400 md:text-sm font-medium border-r border-neutral-400 pr-8'> <a className='font-semibold hover:underline' href="https://www.alohadijital.com/" target="_blank">Aloha Dijital</a> tarafından yapılmıştır.</span>
          <div className='border-l border-neutral-400 mx-3 text-zinc-900'> </div>
          <span className='text-neutral-400 md:text-sm font-medium'>EKONOMİ MANŞET © 2024 - Tüm hakları saklıdır.</span>
        </div>
      </footer>


    </div>
  )
}

export default Footer
