export const newsData = [
  {
    title: "Ekonomide Büyüme Oranı Yükseldi",
    date: "21 Haz 2024",
    author: "Ahmet Yılmaz",
    comments: 2,
    category: ["ÖZEL HABERLER"],
    image:
      "https://mediacdn.yirmidort.tv/Documents/yirmidorthaber/images/2023/12/21/yurt-icinde-gozler-tcmbni-925_2-41.jpg",
  },
  {
    title: "Dolar Kuru Rekor Kırdı",
    date: "20 Haz 2024",
    author: "Mehmet Demir",
    comments: 4,
    category: ["ÖZEL HABERLER"],
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvR-1c5XVOeFxi6NmrHuC7bl6qmxkcOCNVNg&s",
  },
  {
    title: "Borsa İstanbul’da Yeni Zirve",
    date: "19 Haz 2024",
    author: "Ayşe Kaya",
    comments: 3,
    category: ["ÖZEL HABERLER"],
    image:
      "https://img.odatv.com/rcman/Cw1280h720q95gc/storage/files/images/2024/06/02/borsa-istanbulda-bist-100-3923-deger-kazanarak-dunya-listesinde-zirvede-uovh.jpg",
  },
  {
    title: "Enflasyon Rakamları Açıklandı",
    date: "18 Haz 2024",
    author: "Fatma Çelik",
    comments: 1,
    category: ["ÖZEL HABERLER"],
    image:
      "https://img.ulusal.com.tr/rcman/Cw820h461q95gc/storage/files/images/2024/02/05/enflasyon-rakamlari-aciklandi-iste-tuikin-acikladigi-son-veriler-l1bi.jpg",
  },
  {
    title: "Faiz Kararı Gözler TCMB de",
    date: "17 Haz 2024",
    author: "Hakan Uysal",
    comments: 2,
    category: ["ÖZEL HABERLER"],
    image:
      "https://cdn1.ntv.com.tr/gorsel/Vk4DOa57_0eokyIPe70mew.jpg?width=1000&mode=crop&scale=both",
  },
];
