import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './adsmid.css';

const AdsMidTop = () => {
  const [ads, setAds] = useState([]);

  useEffect(() => {
    // API'den verileri çek
    const fetchAds = async () => {
      try {
        const response = await axios.get('http://localhost:3000/api/ads/active');
        setAds(response.data);
      } catch (error) {
        console.error('Error fetching ads:', error);
      }
    };

    fetchAds();
  }, []);

  return (
    <div className='mid-top-ads-container'>
      {ads.map(ad => (
        <a key={ad._id} href={ad.link || "#"}>
          <img src={ad.imageURL} alt={ad.title} />
        </a>
      ))}
    </div>
  );
};

export default AdsMidTop;
