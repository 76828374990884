import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./prayertime.css";

const PrayerTime = () => {

  const cities = [
    'Adana', 'Adıyaman', 'Afyonkarahisar', 'Ağrı', 'Amasya', 'Ankara', 'Antalya', 'Artvin', 'Aydın',
    'Balıkesir', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu', 'Burdur', 'Bursa', 'Çanakkale', 'Çankırı',
    'Çorum', 'Denizli', 'Diyarbakır', 'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir', 'Gaziantep',
    'Giresun', 'Gümüşhane', 'Hakkari', 'Hatay', 'Isparta', 'Mersin', 'İstanbul', 'İzmir', 'Kars', 'Kastamonu',
    'Kayseri', 'Kırklareli', 'Kırşehir', 'Kocaeli', 'Konya', 'Kütahya', 'Malatya', 'Manisa', 'Kahramanmaraş',
    'Mardin', 'Muğla', 'Muş', 'Nevşehir', 'Niğde', 'Ordu', 'Rize', 'Sakarya', 'Samsun', 'Siirt', 'Sinop',
    'Sivas', 'Tekirdağ', 'Tokat', 'Trabzon', 'Tunceli', 'Şanlıurfa', 'Uşak', 'Van', 'Yozgat', 'Zonguldak',
    'Aksaray', 'Bayburt', 'Karaman', 'Kırıkkale', 'Batman', 'Şırnak', 'Bartın', 'Ardahan', 'Iğdır', 'Yalova',
    'Karabük', 'Kilis', 'Osmaniye', 'Düzce'
  ];

  const months = [
    'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
    'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
  ];

  const today = new Date();
  const [city, setCity] = useState('İstanbul');
  const [date, setDate] = useState({
    day: today.getDate().toString().padStart(2, '0'),
    month: (today.getMonth() + 1).toString().padStart(2, '0'),
    year: today.getFullYear().toString()
  });
  const [prayerTimes, setPrayerTimes] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const formattedDate = `${today.getDate().toString().padStart(2, '0')} ${months[today.getMonth()]} ${today.getFullYear()}`;
  const [countdown, setCountdown] = useState('');
  const [nextPrayer, setNextPrayer] = useState('');

  const fetchPrayerTimes = async (selectedCity, selectedDate) => {
    setLoading(true);
    setError('');
    try {
      const { day, month, year } = selectedDate;
      const response = await axios.get(`https://api.aladhan.com/v1/timingsByCity`, {
        params: {
          city: selectedCity,
          country: 'Turkey',
          method: 2, 
          date: `${day}-${month}-${year}`
        }
      });
      setPrayerTimes(response.data.data.timings);
    } catch (err) {
      setError('Namaz vakitleri alınamadı, lütfen tekrar deneyin.');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPrayerTimes(city, date);
  }, [city, date]);

  useEffect(() => {
    
    const calculateCountdown = () => {
      const now = new Date();
      const prayerTimesList = Object.keys(prayerTimes).map(key => ({
        name: key,
        time: new Date(`${date.year}-${date.month}-${date.day}T${prayerTimes[key]}`)
      }));

      const nextPrayer = prayerTimesList.find(prayer => prayer.time > now);
      if (nextPrayer) {
        const diff = nextPrayer.time - now;
        let hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

       
        hours = hours > 0 ? hours : '';

        setCountdown(`${hours}${hours ? ' sa. ' : ''}${minutes} dk. ${seconds} sn. kaldı`);
        setNextPrayer(nextPrayer.name);
      }
    };

    const interval = setInterval(() => {
      calculateCountdown();
    }, 1000);

    return () => clearInterval(interval);
  }, [prayerTimes, date]);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDate({
      ...date,
      [name]: value
    });
  };

  return (
    <div className='prayer-page-container md:px-60'>
      <div className='prayer-page-div p-8 text-left'>

        <div className='text-4xl font-bold mt-4'>
          {formattedDate}
        </div>
        <div className='text-3xl font-bold mt-2'>
          Güncel Namaz Vakitleri
        </div>

        <div className="dropdown mt-4 ">
          <button className="btn btn-secondary dropdown-toggle prayer-page-input" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
            {city}
          </button>
          <ul className="dropdown-menu prayer-page-dropdown-menu" aria-labelledby="dropdownMenuButton">
            {cities.map((cityName) => (
              <li key={cityName}>
                <button className="dropdown-item prayer-page-dropdown-item" onClick={() => setCity(cityName)}>
                  {cityName}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {error && <p>{error}</p>}

        {prayerTimes.Fajr && (
          <div>
            <ul className='text-2xl font-semibold prayer-times-list'>
            <li className={nextPrayer === 'Fajr' ? 'next-prayer' : ''}>İmsak: {prayerTimes.Fajr}</li>
              <li className={nextPrayer === 'Sunrise' ? 'next-prayer' : ''}>Güneş: {prayerTimes.Sunrise}</li>
              <li className={nextPrayer === 'Dhuhr' ? 'next-prayer' : ''}>Öğle: {prayerTimes.Dhuhr}</li>
              <li className={nextPrayer === 'Asr' ? 'next-prayer' : ''}>İkindi: {prayerTimes.Asr}</li>
              <li className={nextPrayer === 'Maghrib' ? 'next-prayer' : ''}>Akşam: {prayerTimes.Maghrib}</li>
              <li className={nextPrayer === 'Isha' ? 'next-prayer' : ''}>Yatsı: {prayerTimes.Isha}</li>
            </ul>
            <p className='mt-4 text-xl font-semibold bg-[#03575e] w-[280px] p-2 rounded-md'>Sıradaki vakte kalan süre: <br /> {countdown}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default PrayerTime;
