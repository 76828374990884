import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import "./searchpage.css";
import SideMenu from "../../components/sideMenu/SideMenu";


const SearchPage = () => {

    const newsItems = [
        {
            id: 1,
            category: "Tech",
            date: "08.22.2023",
            title: "BES ve OKS’nin toplam fon büyüklüğü yılın ilk yarısını 1 trilyon liranın üzerinde kapattı",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/bes1.jpg",
            color: "purple"
        },
        {
            id: 2,
            category: "Tech",
            date: "08.23.2023",
            title: "Gurbetçilere 2 bin Euro vatana destek vergisi mi geliyor? Resmi açıklama yapıldı",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/thumbs_b_c_810ec120f76c5eecaecfc8d9e12b4ea3-740x420.jpg",
            color: "purple"
        },
        {
            id: 3,
            category: "Tech",
            date: "08.24.2023",
            title: "Bakan Tunç’tan kira zam sınırıyla ilgili açıklama",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/02/Yilmaz-Tunc-Adalet-Bakani-1-1.jpg",
            color: "purple"
        },
        {
            id: 4,
            category: "Tech",
            date: "08.25.2023",
            title: "Bakan Şimşek: Borçlanma faiz oranlarımız düşüyor",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/04/Mehmet-Simsek-1-740x420.webp",
            color: "purple"
        },
        {
            id: 5,
            category: "Tech",
            date: "08.26.2023",
            title: "Ali Koç ifadeye çağırıldı",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/06/Ali-Koc-Fenerbahce-Kulubu-Baskani-.jpg",
            color: "purple"
        },
        {
            id: 6,
            category: "Tech",
            date: "08.27.2023",
            title: "Havalimanlarında yeni dönem… Uçağa binmenin kuralı değişti",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/04/Istanbul-Havalimani-1-740x420.webp",
            color: "purple"
        },
        {
            id: 7,
            category: "Tech",
            date: "08.28.2023",
            title: "Meteoroloji’den İstanbul ve 13 il için sarı alarm!",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/sgnk02.png",
            color: "purple"
        },
        {
            id: 8,
            category: "Tech",
            date: "08.29.2023",
            title: "Kara yollarında durum",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/05/kara-yollari-1.jpg",
            color: "purple"
        },
        {
            id: 9,
            category: "Tech",
            date: "08.30.2023",
            title: "SPK 12 şirketin borçlanma aracı ihracı başvurusunu onayladı",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/05/spk-2-740x420.webp",
            color: "purple"
        },
        {
            id: 10,
            category: "Tech",
            date: "08.31.2023",
            title: "Erdoğan ve Esad görüşecek mi?",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/eset-erdogan.jpg",
            color: "purple"
        },
        {
            id: 11,
            category: "Tech",
            date: "09.01.2023",
            title: "31 internet sitesi için kapatma talebi",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/site-kapama.jpeg",
            color: "purple"
        },
        {
            id: 12,
            category: "Tech",
            date: "09.02.2023",
            title: "Hürriyet Gazetecilik şube kapattı",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/Hurriyet-Gazetecilik.jpg",
            color: "purple"
        },
        {
            id: 13,
            category: "Tech",
            date: "09.03.2023",
            title: "CHP lideri Özel: Temmuzda Şam’a gideceğim",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/05/Ozgur-Ozel-740x420.jpg",
            color: "purple"
        },
        {
            id: 14,
            category: "Tech",
            date: "09.04.2023",
            title: "JSGA’ne 523 öğrenci alınacak",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/asker-yemin-toreni-Jandarma-Sahil-Guvenlik-Akademisi-JSGA.jpg",
            color: "purple"
        },
        {
            id: 15,
            category: "Tech",
            date: "09.05.2023",
            title: "Spor spikeri Orhan Ayhan, Guinness Rekorlar Kitabı’na girdi",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/Orhan-Ayhan.jpg",
            color: "purple"
        },
        {
            id: 16,
            category: "Tech",
            date: "09.06.2023",
            title: "İçişleri Bakanlığından, “Sultanbeyli’de ayaklanma çıkarmaya başlayacağız” paylaşımına ilişkin açıklama",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/05/Icisleri-Bakanligi-740x420.jpg",
            color: "purple"
        },
        {
            id: 17,
            category: "Tech",
            date: "09.07.2023",
            title: "Kayseri’deki olaylarla ilgili paylaşım ve gösterilere ilişkin 10 tutuklama",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/Polis-ekip-arabasi.jpg",
            color: "purple"
        },
        {
            id: 18,
            category: "Tech",
            date: "09.08.2023",
            title: "Akaryakıttaki ÖTV’nin sübvanse edilmesini bekliyoruz",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/mader-740x420.webp",
            color: "purple"
        },
        {
            id: 19,
            category: "Tech",
            date: "09.09.2023",
            title: "Kulüpler Birliği Vakfında Ali Koç dönemi sona eriyor",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/03/Ali-Koc-Fenerbahce.jpg",
            color: "purple"
        },
        {
            id: 20,
            category: "Tech",
            date: "09.10.2023",
            title: "Ulaştırma teklifi Meclis Genel Kurulu’nda kabul edildi",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/05/tbmm.jpg",
            color: "purple"
        },
        {
            id: 21,
            category: "Tech",
            date: "09.11.2023",
            title: "TOKİ’den açıklama! Deprem bölgesinde sürekli aynı firmalara mı iş veriliyor?",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/06/toki1-740x420.jpg",
            color: "purple"
        },
        {
            id: 22,
            category: "Tech",
            date: "09.12.2023",
            title: "Mehmet Özhaseki siyaseti bıraktı",
            image: "https://ekonomimanset.com/wp-content/uploads/2023/12/Mehmet-Ozhaseki-2-740x420.jpg",
            color: "purple"
        },
        {
            id: 23,
            category: "Tech",
            date: "09.13.2023",
            title: "Meteoroloji’den sağanak yağış uyarısı",
            image: "https://ekonomimanset.com/wp-content/uploads/2023/03/saganak-yagis.jpg",
            color: "purple"
        },
        {
            id: 24,
            category: "Tech",
            date: "08.31.2023",
            title: "Erdoğan ve Esad görüşecek mi?",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/eset-erdogan.jpg",
            color: "purple"
        },
        {
            id: 25,
            category: "Tech",
            date: "09.01.2023",
            title: "31 internet sitesi için kapatma talebi",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/site-kapama.jpeg",
            color: "purple"
        },
        {
            id: 26,
            category: "Tech",
            date: "09.02.2023",
            title: "Hürriyet Gazetecilik şube kapattı",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/Hurriyet-Gazetecilik.jpg",
            color: "purple"
        },
        {
            id: 27,
            category: "Tech",
            date: "09.03.2023",
            title: "CHP lideri Özel: Temmuzda Şam’a gideceğim",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/05/Ozgur-Ozel-740x420.jpg",
            color: "purple"
        },
        {
            id: 28,
            category: "Tech",
            date: "09.04.2023",
            title: "JSGA’ne 523 öğrenci alınacak",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/asker-yemin-toreni-Jandarma-Sahil-Guvenlik-Akademisi-JSGA.jpg",
            color: "purple"
        },
        {
            id: 29,
            category: "Tech",
            date: "09.05.2023",
            title: "Spor spikeri Orhan Ayhan, Guinness Rekorlar Kitabı’na girdi",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/Orhan-Ayhan.jpg",
            color: "purple"
        },
        {
            id: 30,
            category: "Tech",
            date: "09.06.2023",
            title: "İçişleri Bakanlığından, “Sultanbeyli’de ayaklanma çıkarmaya başlayacağız” paylaşımına ilişkin açıklama",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/05/Icisleri-Bakanligi-740x420.jpg",
            color: "purple"
        },
        {
            id: 31,
            category: "Tech",
            date: "09.07.2023",
            title: "Kayseri’deki olaylarla ilgili paylaşım ve gösterilere ilişkin 10 tutuklama",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/Polis-ekip-arabasi.jpg",
            color: "purple"
        },
        {
            id: 32,
            category: "Tech",
            date: "09.08.2023",
            title: "Akaryakıttaki ÖTV’nin sübvanse edilmesini bekliyoruz",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/mader-740x420.webp",
            color: "purple"
        },
        {
            id: 33,
            category: "Tech",
            date: "09.09.2023",
            title: "Kulüpler Birliği Vakfında Ali Koç dönemi sona eriyor",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/03/Ali-Koc-Fenerbahce.jpg",
            color: "purple"
        },
        {
            id: 34,
            category: "Tech",
            date: "09.10.2023",
            title: "Ulaştırma teklifi Meclis Genel Kurulu’nda kabul edildi",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/05/tbmm.jpg",
            color: "purple"
        },
        {
            id: 35,
            category: "Tech",
            date: "09.11.2023",
            title: "TOKİ’den açıklama! Deprem bölgesinde sürekli aynı firmalara mı iş veriliyor?",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/06/toki1-740x420.jpg",
            color: "purple"
        },
        {
            id: 36,
            category: "Tech",
            date: "09.12.2023",
            title: "Mehmet Özhaseki siyaseti bıraktı",
            image: "https://ekonomimanset.com/wp-content/uploads/2023/12/Mehmet-Ozhaseki-2-740x420.jpg",
            color: "purple"
        },
        
        {
            id: 37,
            category: "Tech",
            date: "09.04.2023",
            title: "JSGA’ne 523 öğrenci alınacak",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/asker-yemin-toreni-Jandarma-Sahil-Guvenlik-Akademisi-JSGA.jpg",
            color: "purple"
        },
        {
            id: 38,
            category: "Tech",
            date: "09.05.2023",
            title: "Spor spikeri Orhan Ayhan, Guinness Rekorlar Kitabı’na girdi",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/Orhan-Ayhan.jpg",
            color: "purple"
        },
        {
            id: 39,
            category: "Tech",
            date: "09.06.2023",
            title: "İçişleri Bakanlığından, “Sultanbeyli’de ayaklanma çıkarmaya başlayacağız” paylaşımına ilişkin açıklama",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/05/Icisleri-Bakanligi-740x420.jpg",
            color: "purple"
        },
        {
            id: 40,
            category: "Tech",
            date: "09.07.2023",
            title: "Kayseri’deki olaylarla ilgili paylaşım ve gösterilere ilişkin 10 tutuklama",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/Polis-ekip-arabasi.jpg",
            color: "purple"
        },
        {
            id: 41,
            category: "Tech",
            date: "09.08.2023",
            title: "Akaryakıttaki ÖTV’nin sübvanse edilmesini bekliyoruz",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/07/mader-740x420.webp",
            color: "purple"
        },
        {
            id: 42,
            category: "Tech",
            date: "09.09.2023",
            title: "Kulüpler Birliği Vakfında Ali Koç dönemi sona eriyor",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/03/Ali-Koc-Fenerbahce.jpg",
            color: "purple"
        },
        {
            id: 43,
            category: "Tech",
            date: "09.10.2023",
            title: "Ulaştırma teklifi Meclis Genel Kurulu’nda kabul edildi",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/05/tbmm.jpg",
            color: "purple"
        },
        {
            id: 44,
            category: "Tech",
            date: "09.11.2023",
            title: "TOKİ’den açıklama! Deprem bölgesinde sürekli aynı firmalara mı iş veriliyor?",
            image: "https://ekonomimanset.com/wp-content/uploads/2024/06/toki1-740x420.jpg",
            color: "purple"
        },
        {
            id: 45,
            category: "Tech",
            date: "09.12.2023",
            title: "Mehmet Özhaseki siyaseti bıraktı",
            image: "https://ekonomimanset.com/wp-content/uploads/2023/12/Mehmet-Ozhaseki-2-740x420.jpg",
            color: "purple"
        },
        


    ]; 

    const [visibleItems, setVisibleItems] = useState(18);

    const showMoreItems = () => {
        setVisibleItems(prevVisibleItems => prevVisibleItems + 9); // 9 items per load, adjust as needed
    };

    const location = useLocation();
  const [query, setQuery] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get('q');
    setQuery(searchQuery);
  }, [location]);

  return (
    <div className='search-page-container'>

            <div className='search-page-top-container md:grid grid-cols-11 md:px-60'>

                <div className='search-page-top-left-container col-span-7 md:pr-[30px]'>

                <h1 className='search-page-title1'>"{query}" için arama sonuçları</h1>

                <div className="search-news-grid-container">
                        <div className="search-news-grid">
                            {newsItems.slice(0, visibleItems).map((item) => (
                                <div key={item.id} className="search-news-item">
                                    <img src={item.image} alt={item.title} />
                                    <div className="search-news-content">
                                        <h3 className="title">{item.title}</h3>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='text-center'>
                        {visibleItems < newsItems.length && (
                            <button onClick={showMoreItems} className="load-more-button search-page-load-more-button ">
                                Daha Fazla Sonuç Yükle
                            </button>
                        )}
                    </div>

                    

                </div>

                <div className='search-page-top-right-container col-span-4 md:pl-[30px]'>

                    <SideMenu />

                </div>

            </div>


        </div>
  )
}

export default SearchPage