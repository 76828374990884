import React from 'react';

const CommentSection = () => {
  return (
    <div className=" border-t my-3">
      <p className="text-2xl font-bold my-3">Yorum Yap</p>
      <form className="mb-6">
        <textarea
          className="w-full p-2 border border-gray-400 rounded mb-3"
          rows="4"
          placeholder="Yorumunu Yaz"
        ></textarea>
        <input
          type="text"
          className="w-full p-2 border border-gray-400 rounded mb-3"
          placeholder="Adınız"
        />
        <div className="flex items-center mb-4">
          <label className="text-sm">
          <input
            type="checkbox"
            className="mr-2"
          />
            Bir dahaki sefere yorum yaptığımda kullanılmak üzere adımı, e-posta adresimi ve web site adresimi bu tarayıcıya kaydet.
          </label>
        </div>
        <button className=" bg-black text-white py-2 px-4 rounded">Yorumu Gönder</button>
      </form>
      <div className="border-t pt-6">
        <div className="flex items-start mb-4">
          <img src="https://www.ekonomimanset.com/wp-content/uploads/2022/10/image_750x_615b0260b7629.jpg" alt="User Avatar" className="w-10 h-10 rounded-full mr-4" />
          <div>
            <p className="text-base font-semibold">
              <span className="text-gray-900">Recep</span>
              <span className="text-gray-500 text-xs font-normal ml-2">30 Haziran 2024, 20:37</span>
            </p>
            <p className="text-gray-800 my-2">
            Altın piyasasında en düşük 2 milyon 457 bin lirayı, en yüksek 2 milyon 505 bin lirayı gören standart altının kilogram fiyatı, gün sonunda yüzde 2,5 artışla 2 milyon 490 bin lira oldu. Dün, standart altının kilogram fiyatı günü 2 milyon 430 bin liradan tamamlamıştı.
            </p>
            <button className="text-red-500 text-sm mt-2">Yanıtla</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommentSection;
