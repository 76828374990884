import React, { useState, useEffect } from 'react';
import axios from 'axios';

//TRENDLER
const API_BASE_URL = "http://localhost:3000/news"; // API URL'nizi buraya koyun

const MenuPosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(API_BASE_URL);
        const data = response.data;

        // Haberleri tarihe göre azalan sırayla sıralama
        const sortedPosts = data.sort((a, b) => new Date(b.updateDate) - new Date(a.updateDate));
        
        // Son 4 haberi seçme
        const recentPosts = sortedPosts.slice(0, 4);

        setPosts(recentPosts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Veriler alınırken bir hata oluştu.");
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) return <div>Yükleniyor...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mt-4 flex flex-col gap-8">
      <div className="p-3">
        {posts.map(post => (
          <a href={`/news/${post.slug}`} key={post._id} className="">
            <div className="flex flex-col gap-1 text-left mt-2">
              <div className="flex flex-row">
                <span className="px-2 py-0.5 rounded-lg text-xs text-white w-max bg-red-400">
                  {post.categories.join(', ')}
                </span>
                <div className="text-xs ms-2">
                  <span className="text-gray-600">{post.author}</span>
                  <span className="text-gray-600"> - {new Date(post.updateDate).toLocaleDateString()}</span>
                </div>
              </div>
              <h3 className="text-base font-medium text-gray-800">
                {post.title}
              </h3>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default MenuPosts;
