import React, { useState, useEffect } from 'react';
import "./adsmobile.css";
import mobilead from "./adsmobile.gif";
import mobilead2 from "./adsmobile.gif";

const slidesData = [
  { id: 1, image: mobilead },
  { id: 2, image: mobilead2 },
];

const AdsMobile = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = slidesData.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    }, 5000); // 5 saniyede bir geçiş

    return () => clearInterval(interval); // Temizlik
  }, [totalSlides]);
  
// en tepedeki slider reklam. carousl üstündeki
  return (
    <div className='mobile-ads-container '>
      <div className="slider ">
        <div
          className="slider-container "
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {slidesData.map((slide) => (
            <div
              className="slide"
              key={slide.id}
            >
              <a
                href={slide.link || "#"}
                className="slideImg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={slide.image} alt={`Slide ${slide.id}`} />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdsMobile;
