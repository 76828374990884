import React, { useState } from "react";
import "./actualNews.css";
import TitleBar from "../titleBar/TitleBar";

const NewsPage = () => {
  const articles = [
    {
      title: "Ekonomik Büyüme: 2024 İçin Öngörüler",
      date: "22 Haziran 2024",
      imageUrl:
        "https://mediacdn.yirmidort.tv/Documents/yirmidorthaber/images/2023/11/29/oecd-kuresel-ekonomik-buy-189_2-41.jpg",
      category: "Borsa",
    },
    {
      title: "Bankacılık Sektöründe Gelişmeler ",
      date: "22 Haziran 2024",
      imageUrl:
        "https://cdnuploads.aa.com.tr/uploads/Contents/2019/12/16/thumbs_b_c_49c07c0e532116767d27caef67b05c3e.jpg",
      category: "Bankacılık",
    },
    {
      title: "Sigorta Şirketleri ve Risk Yönetimi",
      date: "22 Haziran 2024",
      imageUrl:
        "https://i.borsagundem.com/files/2024/3/19/1789769/1789769.jpg?v=1711627965",
      category: "Sigorta",
    },
    {
      title: "Kripto Para Piyasasında Güncel Durum",
      date: "22 Haziran 2024",
      imageUrl:
        "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1512000/bitcoin-kripto-para-1513059.jpg",
      category: "Kripto",
    },
    {
      title: "Döviz Kuru ve Analizler",
      date: "22 Haziran 2024",
      imageUrl:
        "https://iasbh.tmgrup.com.tr/98ec9f/752/395/0/81/726/462?u=https://isbh.tmgrup.com.tr/sbh/2022/01/25/dolar-euro-kuru-ne-kadar-oldu-25-ocak-2022-dolar-ve-euro-kac-tl-gozler-fede-dondu-iste-dolar-yorum-ve-piyasa-a-1643110605548.jpg",
      category: "Döviz",
    },
    {
      title: "Sigorta Şirketleri ve Risk Yönetimi",
      date: "22 Haziran 2024",
      imageUrl:
        "https://i.borsagundem.com/files/2024/3/19/1789769/1789769.jpg?v=1711627965",
      category: "Sigorta",
    },
    {
      title: "Kripto Para Piyasasında Güncel Durum",
      date: "22 Haziran 2024",
      imageUrl:
        "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1512000/bitcoin-kripto-para-1513059.jpg",
      category: "Kripto",
    },
    {
      title: "Döviz Kuru ve Analizler",
      date: "22 Haziran 2024",
      imageUrl:
        "https://iasbh.tmgrup.com.tr/98ec9f/752/395/0/81/726/462?u=https://isbh.tmgrup.com.tr/sbh/2022/01/25/dolar-euro-kuru-ne-kadar-oldu-25-ocak-2022-dolar-ve-euro-kac-tl-gozler-fede-dondu-iste-dolar-yorum-ve-piyasa-a-1643110605548.jpg",
      category: "Döviz",
    },
  ];
  const categories = [
    { name: "Tümü", id: "all" },
    { name: "Borsa", id: "borsa" },
    { name: "Bankacılık", id: "bankacilik" },
    { name: "Sigorta", id: "sigorta" },
    { name: "Kripto", id: "kripto" },
    { name: "Döviz", id: "doviz" },
    { name: "Yazarlar", id: "yazarlar" },
    { name: "Dünya", id: "dünya" },
    { name: "Finans", id: "finans" },
    { name: "Yatırım", id: "yatirim" },
    { name: "Girişimcilik", id: "girisimcilik" },
    { name: "Analizler", id: "piyasa-analizleri" },
    { name: "Göstergeler", id: "ekonomik-gostergeler" },
  ];

  const [activeCategory, setActiveCategory] = useState("Tümü");
  const [currentPage, setCurrentPage] = useState(0);
  const [fade, setFade] = useState(true);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(articles.length / itemsPerPage);

  const handlePrev = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentPage((prevPage) =>
        prevPage > 0 ? prevPage - 1 : totalPages - 1
      );
      setFade(true);
    }, 500);
  };

  const handleNext = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentPage((prevPage) =>
        prevPage < totalPages - 1 ? prevPage + 1 : 0
      );
      setFade(true);
    }, 500);
  };

  const startIndex = currentPage * itemsPerPage;
  const currentArticles = articles.slice(startIndex, startIndex + itemsPerPage);

  const handleCategoryClick = (category) => {
    setActiveCategory(category.name);
  };

  const [featuredArticle, setFeaturedArticle] = useState({
    title: "Mehmet Şimşek Uyardı",
    category: "İç Mekanlar",
    imageUrl:
      "https://denizliaktuelcom.teimg.com/crop/1280x720/denizliaktuel-com/uploads/2024/04/ekonomi-mehmet-simsek-uyardi.jpg",
  });

  const handleArticleClick = (article) => {
    setFeaturedArticle(article);
  };

  return (
    <div className="news-page">
      <div className="header">
        <div className="dont-miss">GÜNDEM</div>
        <TitleBar title="GÜNDEM" className="mobtitle" />
        <nav className="newsTitle">
          {categories.map((category, index) => (
            <a
              key={index}
              href="#"
              className={category.name === activeCategory ? "active" : ""}
              onClick={() => handleCategoryClick(category)}
            >
              {category.name}
            </a>
          ))}
        </nav>
      </div>

      <div className="content">
        <div className="featured-article">
          <img src={featuredArticle.imageUrl} alt={featuredArticle.title} />
          <div className="featured-overlay"></div>
        </div>
        <div className={`article-list ${fade ? "fade-in" : "fade-out"}`}>
          <div className="row">
            <div className="col-md-10 articles-content">
              {currentArticles.map((article, index) => (
                <div
                  key={index}
                  className="article"
                  onClick={() => handleArticleClick(article)}
                >
                  <img
                    src={article.imageUrl}
                    alt={"img " + index}
                    className="article-thumbnail"
                  />
                  <div className="article-info">
                    <h3>{article.title}</h3>
                    <p>{article.date}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-2 m-auto">
              <div className="navigation navigation-desktop">
                <span className="nav-button-left" onClick={handlePrev}>
                  <i className="fa-solid fa-chevron-up"></i>
                </span>
                <span className="nav-button-right" onClick={handleNext}>
                  <i className="fa-solid fa-chevron-down"></i>
                </span>
              </div>
              <div className="navigation navigation-mobile">
                <span className="nav-button-left" onClick={handlePrev}>
                  <i className="fa-solid fa-chevron-left"></i>
                </span>
                <span className="nav-button-right" onClick={handleNext}>
                  <i className="fa-solid fa-chevron-right"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
