import React from 'react';
import "./trafficinfo.css";


const TrafficInfo = () => {
    return (
        <div className='traffic-page-container md:px-60'>

            <ul class="nav " id="myTab" role="tablist">
                <div className='traffic-city-tab-list grid grid-cols-5 w-full'>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active city-nav-link" id="istanbul-tab" data-bs-toggle="tab" data-bs-target="#istanbul-tab-pane" type="button" role="tab" aria-controls="istanbul-tab-pane" aria-selected="true">İstanbul</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link city-nav-link" id="ankara-tab" data-bs-toggle="tab" data-bs-target="#ankara-tab-pane" type="button" role="tab" aria-controls="ankara-tab-pane" aria-selected="false">Ankara</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link city-nav-link" id="izmir-tab" data-bs-toggle="tab" data-bs-target="#izmir-tab-pane" type="button" role="tab" aria-controls="izmir-tab-pane" aria-selected="false">İzmir</button>
                    </li>
                    <li class="nav-item " role="presentation">
                        <button class="nav-link city-nav-link" id="bursa-tab" data-bs-toggle="tab" data-bs-target="#bursa-tab-pane" type="button" role="tab" aria-controls="bursa-tab-pane" aria-selected="false">Bursa</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link city-nav-link" id="antalya-tab" data-bs-toggle="tab" data-bs-target="#antalya-tab-pane" type="button" role="tab" aria-controls="antalya-tab-pane" aria-selected="false">Antalya</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link city-nav-link" id="konya-tab" data-bs-toggle="tab" data-bs-target="#konya-tab-pane" type="button" role="tab" aria-controls="konya-tab-pane" aria-selected="false">Konya</button>
                    </li>
                    <li class="nav-item " role="presentation">
                        <button class="nav-link city-nav-link" id="adana-tab" data-bs-toggle="tab" data-bs-target="#adana-tab-pane" type="button" role="tab" aria-controls="adana-tab-pane" aria-selected="false">Adana</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link city-nav-link" id="sanliurfa-tab" data-bs-toggle="tab" data-bs-target="#sanliurfa-tab-pane" type="button" role="tab" aria-controls="sanliurfa-tab-pane" aria-selected="false">Şanlıurfa</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link city-nav-link" id="gaziantep-tab" data-bs-toggle="tab" data-bs-target="#gaziantep-tab-pane" type="button" role="tab" aria-controls="gaziantep-tab-pane" aria-selected="false">Gaziantep</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link city-nav-link" id="kocaeli-tab" data-bs-toggle="tab" data-bs-target="#kocaeli-tab-pane" type="button" role="tab" aria-controls="kocaeli-tab-pane" aria-selected="false">Kocaeli</button>
                    </li>
                </div>
            </ul>
            <div class="tab-content traffic-tab-content mt-2" id="myTabContent">
                <div class="tab-pane fade show active" id="istanbul-tab-pane" role="tabpanel" aria-labelledby="istanbul-tab" tabindex="0">
                <h1 className='traffic-page-title1'>İstanbul Yol Durumu</h1>
                <div style={{ position: 'relative', overflow: 'hidden', }}>
      <a href="https://yandex.com.tr/harita/115707/fatih/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 0 }}>Fatih</a>
      <a href="https://yandex.com.tr/harita/115707/fatih/?l=trf%2Ctrfe&ll=28.932279%2C41.015271&utm_medium=mapframe&utm_source=maps&z=11.11" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 14 }}>Yandex Haritalar</a>
      <iframe
        src="https://yandex.com.tr/map-widget/v1/?l=trf%2Ctrfe&ll=28.932279%2C41.015271&z=11.11"
        width="100%"
        height="500"
        frameBorder="1"
        allowFullScreen
        style={{ position: 'relative' }}
      ></iframe>
    </div>

                </div>
                <div class="tab-pane fade" id="ankara-tab-pane" role="tabpanel" aria-labelledby="ankara-tab" tabindex="0">
                <h1 className='traffic-page-title1'>Ankara Yol Durumu</h1>
                    <div style={{ position: 'relative', overflow: 'hidden' }}>
                        
                        <a href="https://yandex.com.tr/harita/115676/altindag/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 0 }}>Altındağ</a>
                        <a href="https://yandex.com.tr/harita/115676/altindag/?l=trf%2Ctrfe&ll=32.825651%2C39.906993&utm_medium=mapframe&utm_source=maps&z=11.11" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 14 }}>Yandex Haritalar</a>
                        <iframe
                            src="https://yandex.com.tr/map-widget/v1/?l=trf%2Ctrfe&ll=32.825651%2C39.906993&z=11.11"
                            width="100%"
                            height="500"
                            frameBorder="1"
                            allowFullScreen
                            style={{ position: 'relative' }}
                        ></iframe>
                    </div>

                </div>
                <div class="tab-pane fade" id="izmir-tab-pane" role="tabpanel" aria-labelledby="izmir-tab" tabindex="0">
                <h1 className='traffic-page-title1'>İzmir Yol Durumu</h1>
                    <div style={{ position: 'relative', overflow: 'hidden' }}>
                        <a href="https://yandex.com.tr/harita?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 0 }}>Yandex Haritalar</a>
                        <a href="https://yandex.com.tr/harita/?l=trf%2Ctrfe&ll=27.143297%2C38.419461&utm_medium=mapframe&utm_source=maps&z=11.68" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 14 }}>Yandex Haritalar</a>
                        <iframe
                            src="https://yandex.com.tr/map-widget/v1/?l=trf%2Ctrfe&ll=27.143297%2C38.419461&z=11.68"
                            width="100%"
                            height="500"
                            frameBorder="1"
                            allowFullScreen
                            style={{ position: 'relative' }}
                        ></iframe>
                    </div>

                </div>
                <div class="tab-pane fade" id="bursa-tab-pane" role="tabpanel" aria-labelledby="bursa-tab" tabindex="0">
                <h1 className='traffic-page-title1'>Bursa Yol Durumu</h1>
                    <div style={{ position: 'relative', overflow: 'hidden' }}>
                        <a href="https://yandex.com.tr/harita/11504/bursa/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 0 }}>Bursa</a>
                        <a href="https://yandex.com.tr/harita/11504/bursa/?l=trf%2Ctrfe&ll=29.074785%2C40.201703&utm_medium=mapframe&utm_source=maps&z=12.65" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 14 }}>Yandex Haritalar</a>
                        <iframe
                            src="https://yandex.com.tr/map-widget/v1/?l=trf%2Ctrfe&ll=29.074785%2C40.201703&z=12.65"
                            width="100%"
                            height="500"
                            frameBorder="1"
                            allowFullScreen
                            style={{ position: 'relative' }}
                        ></iframe>
                    </div>

                </div>
                <div class="tab-pane fade" id="antalya-tab-pane" role="tabpanel" aria-labelledby="antalya-tab" tabindex="0">
                <h1 className='traffic-page-title1'>Antalya Yol Durumu</h1>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
      <a href="https://yandex.com.tr/harita/11511/antalya/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 0 }}>Antalya</a>
      <a href="https://yandex.com.tr/harita/11511/antalya/?l=trf%2Ctrfe&ll=30.709523%2C36.889083&utm_medium=mapframe&utm_source=maps&z=12.11" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 14 }}>Yandex Haritalar</a>
      <iframe
        src="https://yandex.com.tr/map-widget/v1/?l=trf%2Ctrfe&ll=30.709523%2C36.889083&z=12.11"
        width="100%"
        height="500"
        frameBorder="1"
        allowFullScreen
        style={{ position: 'relative' }}
      ></iframe>
    </div>

                </div>
                <div class="tab-pane fade" id="konya-tab-pane" role="tabpanel" aria-labelledby="konya-tab" tabindex="0">
                <h1 className='traffic-page-title1'>Konya Yol Durumu</h1>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
      <a href="https://yandex.com.tr/harita/101474/konya/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 0 }}>Konya</a>
      <a href="https://yandex.com.tr/harita/101474/konya/?l=trf%2Ctrfe&ll=32.508600%2C37.871942&utm_medium=mapframe&utm_source=maps&z=12.35" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 14 }}>Yandex Haritalar</a>
      <iframe
        src="https://yandex.com.tr/map-widget/v1/?l=trf%2Ctrfe&ll=32.508600%2C37.871942&z=12.35"
        width="100%"
        height="500"
        frameBorder="1"
        allowFullScreen
        style={{ position: 'relative' }}
      ></iframe>
    </div>

                </div>
                <div class="tab-pane fade" id="adana-tab-pane" role="tabpanel" aria-labelledby="adana-tab" tabindex="0">
                <h1 className='traffic-page-title1'>Adana Yol Durumu</h1>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
      <a href="https://yandex.com.tr/harita/115737/cukurova/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 0 }}>Çukurova</a>
      <a href="https://yandex.com.tr/harita/115737/cukurova/?l=trf%2Ctrfe&ll=35.312760%2C37.007525&utm_medium=mapframe&utm_source=maps&z=12.6" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 14 }}>Yandex Haritalar</a>
      <iframe
        src="https://yandex.com.tr/map-widget/v1/?l=trf%2Ctrfe&ll=35.312760%2C37.007525&z=12.6"
        width="100%"
        height="500"
        frameBorder="1"
        allowFullScreen
        style={{ position: 'relative' }}
      ></iframe>
    </div>

                </div>
                <div class="tab-pane fade" id="sanliurfa-tab-pane" role="tabpanel" aria-labelledby="sanliurfa-tab" tabindex="0">
                <h1 className='traffic-page-title1'>Şanlıurfa Yol Durumu</h1>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
      <a href="https://yandex.com.tr/harita/200214/haliliye/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 0 }}>Haliliye</a>
      <a href="https://yandex.com.tr/harita/200214/haliliye/?l=trf%2Ctrfe&ll=38.794004%2C37.146564&utm_medium=mapframe&utm_source=maps&z=12.67" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 14 }}>Yandex Haritalar</a>
      <iframe
        src="https://yandex.com.tr/map-widget/v1/?l=trf%2Ctrfe&ll=38.794004%2C37.146564&z=12.67"
        width="100%"
        height="500"
        frameBorder="1"
        allowFullScreen
        style={{ position: 'relative' }}
      ></iframe>
    </div>

                </div>
                <div class="tab-pane fade" id="gaziantep-tab-pane" role="tabpanel" aria-labelledby="gaziantep-tab" tabindex="0">
                <h1 className='traffic-page-title1'>Gaziantep Yol Durumu</h1>   
                <div style={{ position: 'relative', overflow: 'hidden' }}>
      <a href="https://yandex.com.tr/harita/103825/gaziantep/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 0 }}>Gaziantep</a>
      <a href="https://yandex.com.tr/harita/103825/gaziantep/?l=trf%2Ctrfe&ll=37.375101%2C37.066495&utm_medium=mapframe&utm_source=maps&z=12.53" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 14 }}>Yandex Haritalar</a>
      <iframe
        src="https://yandex.com.tr/map-widget/v1/?l=trf%2Ctrfe&ll=37.375101%2C37.066495&z=12.53"
        width="100%"
        height="500"
        frameBorder="1"
        allowFullScreen
        style={{ position: 'relative' }}
      ></iframe>
    </div>

                </div>
                <div class="tab-pane fade" id="kocaeli-tab-pane" role="tabpanel" aria-labelledby="kocaeli-tab" tabindex="0">
                <h1 className='traffic-page-title1'>Kocaeli Yol Durumu</h1>  
                <div style={{ position: 'relative', overflow: 'hidden' }}>
      <a href="https://yandex.com.tr/harita/101515/izmit/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 0 }}>İzmit</a>
      <a href="https://yandex.com.tr/harita/101515/izmit/?l=trf%2Ctrfe&ll=29.933148%2C40.761516&utm_medium=mapframe&utm_source=maps&z=12.77" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: 14 }}>Yandex Haritalar</a>
      <iframe
        src="https://yandex.com.tr/map-widget/v1/?l=trf%2Ctrfe&ll=29.933148%2C40.761516&z=12.77"
        width="100%"
        height="500"
        frameBorder="1"
        allowFullScreen
        style={{ position: 'relative' }}
      ></iframe>
    </div>

                </div>
            </div>


        </div>
    )
}

export default TrafficInfo