import React from "react";
import "./aboutPage.css";
import ArticleList from "../../components/minCards/minCards";

const About = () => {
  return (
    <div className="about-container lg:px-60">
      <div className=" bg-gray-100 p-4 shadow-xl border-b-[5px] border-red-400">
  <h1 className="text-4xl font-bold " >Hakkımızda</h1>
</div>
      <div className="text-left mt-6 mb-4 p-[10px] lg:p-0">
        <p className="text-xl font-semibold mb-3 text-center">
          ERİŞİM MEDYA RADYO TV VE DERGİ YAYINCILIK A.Ş.
        </p>
        <p className="text-base ">
          Dergi ve internet haberciliği alanında butik hizmet veren Erişim Medya
          Grubu 2011 yılında kuruldu. Odak noktası finans sektörü olmak üzere,
          finansal teknolojiler, sigortacılık ve ödeme sistemleri uzmanlık
          alanları içerisinde yer alıyor.
          <br />
        
          Yazılı basındaki ilk yatırımı Akıllı Yaşam Dergisi, Türkiye’nin en
          önemli sektörleri arasında yer alan sigorta ve bireysel emeklilikte
          önemli bir boşluğu dolduruyor.
          
          <br />
          Grubun ikinci yayını PSM Dergisi ise (Payment Systems Magazine),
          Türkiye’nin ödeme sistemlerine yönelik basılı ilk ve tek yayın organı
          olma konumunu kurulduğu yıldan bu yana başarıyla koruyor. PSM uçtan
          uca ödeme sistemlerine yönelik özgün ve katma değerli içerikleriyle
          finans ve teknoloji sektörünün başvuru kaynağı durumunda.
          
          <br />
          Grup finans, teknoloji ve sigorta sektörüne yönelik düzenlediği ödül
          programlarıyla da sektöründeki öncü konumunu koruyor. Özellikle kız
          çocuklarına yönelik sosyal sorumluluk projeleriyle, sektördeki
          şirketlerle iş birliğine giderek daha büyük bir sinerji oluşturma
          hedefiyle yoluna devam ediyor.
        </p>
        <p className="text-xl font-semibold mb-3 mt-4">
        AKILLI YAŞAM DERGİSİ
        </p>
        <p className="text-base ">
        Türkiye’nin en önemli sektörleri arasında yer alan sigorta ve bireysel emeklilikte önemli bir boşluğu dolduran Akıllı Yaşam Dergisi, 2011 yılında yayın hayatına başladı. Sigortacılığı sadece poliçe satan bir sektörden çok daha fazlası olarak benimseyen, en yenilikçi ürün ve müşteri memnuniyeti odaklı hizmet anlayışını yaptığı habercilikle taçlandıran Akıllı Yaşam, sigortacılık konusunda dünya literatürünü takip ederek sektöre ışık tutan yayın organı konumunda. Dergi abonelik sistemiyle dağıtılmakta ayrıca sigortagundem.com ve akilliyasamdergisi.com web sitelerinde de her ayın ilk günü yayınlanıyor.
        </p>

        <p className="text-xl font-semibold mb-3 mt-4">
        PSM (Payment Systems Magazine)
        </p>
        <p className="text-base ">
        2008 yılında yayın hayatına başlayan PSM, Türkiye’nin ödeme sistemlerine yönelik basılı ilk ve tek yayın organı. Aralık 2013’te Erişim Medya bünyesinde katılan PSM, uçtan uca ödeme sistemlerine yönelik özgün ve katma değerli içeriklerle; finans ve teknoloji dünyasının başvuru kaynağı durumunda. Teknolojinin finansla kesiştiği ve finansal hizmetlerin daha verimli hale gelerek değiştiği günümüzde, bu değişimdeki yıkıcı yenilikleri PSM’den takip edebilirsiniz. Abonelik sistemiyle dağıtılan PSM Dergisi ayrıca eDergi olarak da psmmag.com web sitesinde ve Turkcell Dergilik’te her ayın ilk günü okuyucularıyla buluşuyor.
        </p>

        <p className="text-xl font-semibold mb-3 mt-4">
        SİGORTA GÜNDEM
        </p>
        <p className="text-base ">
        Sigorta Gündem, sigorta sektörünün bilgi portalı olarak her gün binlerce tekil kullanıcı tarafında ziyaret ediliyor. Ekonomiye yön veren bütün sektörlerde faaliyet gösteren bir yayın grubu olmak hedefiyle, diğer sektörlerde yarattığı öncü ve belirleyici kimliklerine uygun, onlardan aşağıda kalmayacak bir içerik kalitesiyle sigorta dünyasına ışık tutuyor. sigortagundem.com’da sektördeki hızlı gelişmeleri takip etmenin yanı sıra, sektörün geleceğine yönelik en yenilikçi ürün ve hizmetlere hızlı ve kolay şekilde ulaşabilirsiniz.
        </p>

        <p className="text-xl font-semibold mb-3 mt-4">
        PSM MAG
        </p>
        <p className="text-base ">
        psmmag.com başta finans ve finansal teknolojiler olmak üzere bu sektörlerle ilgili inovatif haberleri ilk yayınlayan ekonomi portalı olarak sektörde öncü konumunu koruyor.
        </p>

        <p className="text-xl font-semibold mb-3 mt-4">
        EKONOMİ MANŞET
        </p>
        <p className="text-base ">
        Erişim Medya’nın en genç üyesi ekonomimanset.com, 2020 yılında yayın hayatına başladı. Kısa sürede ulaştığı tekil kullanıcı sayısı ile ekonomi yayıncılığında lider olma hedefiyle yoluna devam ediyor.
        </p>
        <p className="text-xl font-semibold mb-3 mt-4 underline underline-offset-2 text-center"> ÖDÜL PROGRAMLARI </p>
        <div className="flex flex-col md:flex-row gap-3 md:pr-3">
        
        <img src="https://psmawards.com/_img/sayfa_image1_1645382553_venue1.jpg" alt="Image 1" className="md:w-1/2 w-full h-auto hidden md:block" />
        
        
        <img src="https://psmawards.com/_img/sayfa_image1_1645382574_venue-2.jpg" alt="Image 2" className="md:w-1/2 w-full h-auto " />
        
        </div>
        <p className="text-xl font-semibold mb-3 mt-4">
        PSM AWARDS
        </p>
        <p className="text-base ">
        Finans ve teknoloji sektörünün gelişmesini hedef alan yenilikçi ürün ve hizmetleri desteklemek, inovatif projeleri öne çıkararak bunların gelişimindeki iş modellerinin paylaşılmasını sağlamak ve sektördeki yaratıcılığı teşvik etmek amacıyla 2018 yılından bu yana düzenleniyor.
        </p>

        <p className="text-xl font-semibold mb-3 mt-4">
        Smart-i Awards (Smart Insurance Awards)
          </p>
          <p className="text-base ">
          2022 yılında sigorta sektöründe inovasyonu desteklemek ve başarılı projeleri ödüllendirmek üzere projelendirilen yarışma sigorta sektöründeki paydaşların bir araya gelmesiyle oluşturulacak diyalog ortamının sinerjisini tüm aktörler için bir kazan-kazan ekosistemine dönüştürmek amacıyla düzenleniyor.
          </p>



      </div>
      <ArticleList />
    </div>
  );
};

export default About;
