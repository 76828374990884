import React from 'react';
import "./adsmid.css"
import mbad1 from "./midbtmad1.jpg";
import mbad2 from "./midbtmad2.jpeg";


const AdsMidBottom = () => {
  return (
    <div className='mid-bottom-ads-container'>
        <a href="#"><img src={mbad1} alt="Advertisement Mid Bottom" /></a>
        <a href="#"><img src={mbad2} alt="Advertisement Mid Bottom" /></a>
        <h4>

        </h4>
    </div>
  )
}

export default AdsMidBottom