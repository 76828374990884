import React from "react";
import "./animatedText.css";

const AnimatedText = () => {
  return (
    <div>
      <div className="run-text page">
        <div className="ticker-wrap">
          <div className="ticker-news-text">
            <span className="breaking">SON DAKİKA</span>
          </div>
          <div className="ticker">
            <div className="ticker__item">
              <a href="#">
                Bank of America CEO: "Dijital bankacılık müşteri deneyimini
                dönüştürüyor"
              </a>
            </div>
            <div className="ticker__item">
              <a href="#">
                JP Morgan, 2024 yılı için global ekonomik büyüme tahminlerini
                yükseltti
              </a>
            </div>
            <div className="ticker__item">
              <a href="#">
                Merkez Bankası faiz oranlarını sabit tutma kararı aldı
              </a>
            </div>
            <div className="ticker__item">
              <a href="#">
                Kripto para piyasalarında dalgalanma devam ediyor: Bitcoin
                40,000$ seviyesini aştı
              </a>
            </div>
            <div className="ticker__item">
              <a href="#">
                Goldman Sachs, fintech yatırımlarına hız kesmeden devam ediyor
              </a>
            </div>
            <div className="ticker__item">
              <a href="#">
                IMF, gelişmekte olan piyasalar için büyüme tahminini %4'e
                çıkardı
              </a>
            </div>
            <div className="ticker__item">
              <a href="#"><span className="dollar">$ 32,80</span></a>
              <a href="#"><span className="euro">€ 35,31</span></a>
              <a href="#"><span className="pound">£ 42,20</span></a>
              <a href="#"><span className="gold">GAU 2438,20</span></a>
              <a href="#"><span className="bitcoin">BTC 38,458</span></a>
            </div>
            <div className="ticker__item">
              <a href="#">
                IMF, gelişmekte olan piyasalar için büyüme tahminini %4'e
                çıkardı
              </a>
            </div>
            <div className="ticker__item">
              <a href="#">
                Goldman Sachs, fintech yatırımlarına hız kesmeden devam ediyor
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedText;
