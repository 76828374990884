import React, { useEffect, useState } from "react";
import axios from "axios";
import "./trendnews.css";
import TitleBar from "../titleBar/TitleBar";

//Populer News
const LeagueTable = () => {
  const teams = [
    { position: 1, name: "Galatasaray", played: 38, goalDifference: 66, points: 102 },
    { position: 2, name: "Fenerbahçe", played: 38, goalDifference: 68, points: 99 },
    { position: 3, name: "Trabzonspor", played: 38, goalDifference: 19, points: 67 },
    { position: 4, name: "Başakşehir", played: 38, goalDifference: 14, points: 61 },
    { position: 5, name: "Kasımpaşa", played: 38, goalDifference: -3, points: 56 },
  ];

  return (
    <div className="league-table">
      <TitleBar title="Puan Durumu" />
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>O</th>
            <th>G</th>
            <th>P</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team) => (
            <tr key={team.position}>
              <td>{team.position}</td>
              <td>{team.name}</td>
              <td>{team.played}</td>
              <td>{team.goalDifference > 0 ? `+${team.goalDifference}` : team.goalDifference}</td>
              <td>{team.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="view-table">Daha fazla</button>
    </div>
  );
};

const Trending = () => {
  const [ads, setAds] = useState([]);
  const [popularNews, setPopularNews] = useState([]);

  useEffect(() => {
    // Reklam verilerini API'den çekme
    axios.get("http://localhost:3000/api/ads")
      .then((response) => {
        // Filtreleyip, `Sidemenü Reklam` başlığını içeren reklamları alıyoruz
        const filteredAds = response.data.filter((ad) => ad.title.includes("Sidemenü Reklam"));
        setAds(filteredAds);
      })
      .catch((error) => {
        console.error("Error fetching ads:", error);
      });

    // Popüler haberleri API'den çekme
    axios.get("http://localhost:3000/news")
      .then((response) => {
        console.log("API Response:", response.data); // Yanıtı kontrol edin
        // `isPopularNews` true olan haberleri filtreliyoruz
        const popularNewsItems = response.data.filter((news) => news.isPopularNews);
        setPopularNews(popularNewsItems);
      })
      .catch((error) => {
        console.error("Error fetching popular news:", error);
      });
  }, []);

  return (
    <div className="trends">
      <div className="trending">
        <TitleBar title="Popüler Haberler" />
        <ul>
          {popularNews.map((item, index) => (
            <li key={index}>
              <span className="text">{item.title}</span>
              <span style={{ marginLeft: "5px" }} className="arrow">
                {" "}
                &gt;{" "}
              </span>
            </li>
          ))}
        </ul>
        <br />
        <div className="ads-trends">
          {ads.slice(0, 3).map((ad) => (
            <img
              key={ad._id}
              style={{ margin: "0 auto" }}
              src={ad.imageURL}
              alt={ad.title}
              className="mb-2"
            />
          ))}
        </div>
        <LeagueTable />
      </div>
    </div>
  );
};

export default Trending;
