import React from 'react';
import "./adsmid.css"
import mbad1 from "./midbtmad1.jpg";
import mbad2 from "./midbtmad2.jpeg";


const AdsMidBottom2 = () => {
  return (
    <div className='mid-bottom-ads-container reklam2'>
        {/* <a href="#"><img src={mbad1} alt="Advertisement Mid Bottom" /></a> */}
        <a href="#"><img src={mbad2} alt="Advertisement Mid Bottom" /></a>
    </div>
  )
}

export default AdsMidBottom2