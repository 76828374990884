import React, { useEffect, useState } from "react";
import axios from "axios";
import TitleBar from "../titleBar/TitleBar";
import SliderCard from "../sliderCard/SliderCard";
import Smallcards from "../smallcards/smallcards";


//HOT NEWS
const BigCardList = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // API'den veriyi çekme
    axios.get("http://localhost:3000/news")
      .then(response => {
        // Veriyi sıralama ve son 3 haberi seçme
        const sortedPosts = response.data
          .filter(post => post.isHotNews)
          .sort((a, b) => new Date(b.updateDate) - new Date(a.updateDate))
          .slice(0, 3);
        
        setPosts(sortedPosts);
      })
      .catch(error => {
        console.error("Error fetching news:", error);
      });
  }, []);

  return (
    <div className="col-span-1 p-[10px] lg:col-span-2 lg:p-0">
      <TitleBar title="Sıcak Haberler" />

      <div className="flex flex-col gap-3 mt-4 hotnews-content">
        {posts.length === 0 ? (
          <p>No hot news available.</p>
        ) : (
          posts.map((post, index) => (
            <div key={index} className="">
              <div className="flex flex-col lg:flex-row items-center gap-3 bg-[#f7f7f7] shadow-lg ">
                <div className="w-full flex-1 h-64 md:h-auto relative">
                  <div className="overflow-hidden">
                    <img
                      src={post.image || "default-image-url.jpg"} // Fallback for missing images
                      alt={post.title}
                      className="object-cover w-full h-full transition-transform duration-200 hover:scale-110"
                    />
                  </div>
                </div>
                <div className="p-2 flex-1 text-left">
                  <div className="mb-4">
                    <span className="text-gray-600">{new Date(post.updateDate).toLocaleDateString()} - </span>
                    <span className="text-red-900 font-bold">DÜNYA</span> {/* Update this dynamically if needed */}
                  </div>
                  <h1 className="font-bold text-base mb-2">
                    {post.title}
                  </h1>
                  <div className="text-gray-700 text-sm mb-2">
                    {post.newsText.substring(0, 130)}...
                  </div>
                  <a
                    href={`/news/${post.slug}`}
                    className="text-gray-600 text-sm hover:text-gray-800 border-b border-gray-600 hover:border-gray-800"
                  >
                    Devamını Oku
                  </a>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <SliderCard/>
      <Smallcards/>
    </div>
  );
};

export default BigCardList;
