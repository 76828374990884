import React, { useState, useEffect } from 'react';
import "./categoryPage.css";
import SideMenu from "../../components/sideMenu/SideMenu";
import MostRead from './MostRead';
import MostCommented from './MostCommented';

const CryptoCategory = () => {
    const [newsItems, setNewsItems] = useState([]);
    const [visibleItems, setVisibleItems] = useState(18);

    // API'den veriyi çekme
    useEffect(() => {
        const fetchNewsItems = async () => {
            try {
                const response = await fetch('http://localhost:3000/news'); // API endpoint'ini güncelleyin
                const data = await response.json();
                // Kripto para içeren haberleri filtrele
                const filteredNews = data.filter(item => item.categories.includes('Reel Ekonomi'));
                setNewsItems(filteredNews);
            } catch (error) {
                console.error("Haberler çekilirken bir hata oluştu:", error);
            }
        };
        
        fetchNewsItems();
    }, []);

    const showMoreItems = () => {
        setVisibleItems(prevVisibleItems => prevVisibleItems + 9); // 9 items per load, adjust as needed
    };

    return (
        <div className='category-page-container'>
            <div className='category-page-top-container md:grid grid-cols-11 md:px-60'>
                <div className='category-page-top-left-container col-span-7 md:pr-[30px]'>
                    <div className="category-news-grid-container">
                        <div className="category-news-grid">
                            {newsItems.slice(0, visibleItems).map((item) => (
                                <div key={item._id} className="category-news-item">
                                    <img src={item.image} alt={item.title} />
                                    <div className="category-news-content">
                                        <h3 className="title">{item.title}</h3>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='text-center'>
                        {visibleItems < newsItems.length && (
                            <button onClick={showMoreItems} className="load-more-button category-page-load-more-button ">
                                Daha Fazla Haber Yükle
                            </button>
                        )}
                    </div>
                    <div className='lg:grid grid-cols-2 gap-4 mt-4'>
                        <MostRead />
                        <MostCommented />
                    </div>
                </div>
                <div className='category-page-top-right-container col-span-4 md:pl-[30px]'>
                    <SideMenu />
                </div>
            </div>
        </div>
    );
}

export default CryptoCategory;
