import React, { useRef, useState, useEffect } from 'react';
import "./livetv.css";

const LiveTv = () => {
    const channels = [
        { id: 'trt1', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/TRT_1_logo_%282021-%29.svg/561px-TRT_1_logo_%282021-%29.svg.png', name: 'TRT1', src: 'https://www.tabii.com/tr/watch/live/trt1?trackId=150002' },

        { id: 'showtv', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Show_TV_logo.svg/1024px-Show_TV_logo.svg.png', name: 'Show TV', src: 'https://www.canlitv.day/embed/?id=4' },

        { id: 'startv', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/StarTV.svg/881px-StarTV.svg.png', name: 'Star Tv', src: 'https://www.canlitv.day/embed/?id=99' },

        { id: 'atv', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Atv_logo_2010.svg/1240px-Atv_logo_2010.svg.png', name: 'ATV', src: 'https://www.atv.com.tr/canli-yayin' },

        { id: 'tv8', logo: 'https://upload.wikimedia.org/wikipedia/tr/6/68/Tv8_Yeni_Logo.png', name: 'TV8', src: 'https://www.canlitv.day/embed/?id=10924' },

        { id: 'kanald', logo: 'https://upload.wikimedia.org/wikipedia/tr/4/4e/Kanal_D.png', name: 'Kanal D', src: 'https://www.canlitv.day/embed/?id=2' },

        { id: 'nowtv', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/NOW_TV_%28Turkey%29_wordmark-red.svg/1920px-NOW_TV_%28Turkey%29_wordmark-red.svg.png', name: 'Now Tv', src: 'https://www.nowtv.com.tr/dbc5cc5f-dac8-4004-acf8-4c91e1ae5db0' },

        { id: 'cnbce', logo: 'https://upload.wikimedia.org/wikipedia/commons/b/b9/CNBC-e-Logo.png', name: 'CNBC-e', src: 'https://www.canlitv.day/embed/?id=13406' },

        { id: '360tv', logo: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/360_tv.png', name: '360 Tv', src: 'https://www.canlitv.day/embed/?id=15' },

        { id: 'beyaztv', logo: 'https://upload.wikimedia.org/wikipedia/tr/8/8d/Beyaz_TV_logo.jpeg', name: 'Beyaz Tv', src: 'https://www.canlitv.day/embed/?id=11675' },

        { id: 'trthaber', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/TRT_Haber_Eyl%C3%BCl_2020_Logo.svg/1920px-TRT_Haber_Eyl%C3%BCl_2020_Logo.svg.png', name: 'TRT Haber', src: 'https://www.canlitv.day/embed/?id=19' },

        { id: 'cnnturk', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/CNN_T%C3%BCrk_logo.svg/1288px-CNN_T%C3%BCrk_logo.svg.png', name: 'CNN Türk', src: 'https://www.youtube.com/embed/X_EWYemclKA?si=Ot1OrYTczCFc1plZ' },

        { id: 'ntv', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/NTV_%28Turkey%29_logo.svg/1920px-NTV_%28Turkey%29_logo.svg.png', name: 'NTV', src: 'https://www.canlitv.day/embed/?id=6' },

        { id: 'bloomberght', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Bloomberg_HT_logo.svg/1920px-Bloomberg_HT_logo.svg.png', name: 'Bloomberg HT', src: 'https://www.canlitv.day/embed/?id=12143' },

        { id: 'ahaber', logo: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Ahaber_Logo.png', name: 'A Haber', src: 'https://www.canlitv.day/embed/?id=11501' },

        { id: 'haberturk', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Habert%C3%BCrk_TV_logo.svg/1920px-Habert%C3%BCrk_TV_logo.svg.png', name: 'Habertürk TV', src: 'https://www.canlitv.day/embed/?id=894' },

        { id: 'halktv', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/42/Halk_TV_logo.png', name: 'Halk TV', src: 'https://www.canlitv.day/embed/?id=11102' },

        { id: 'sozcutv', logo: 'https://upload.wikimedia.org/wikipedia/tr/e/ed/SZC_TV_logo.png', name: 'Sözcü TV', src: 'https://www.canlitv.day/embed/?id=12343' },

        { id: 'globaltv', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Haber_Global_logo.svg/1920px-Haber_Global_logo.svg.png', name: 'Global TV', src: 'https://www.canlitv.day/embed/?id=12999' },

        { id: 'tv100', logo: 'https://upload.wikimedia.org/wikipedia/tr/0/0f/TV100_logo.png', name: 'TV 100', src: 'https://www.canlitv.day/embed/?id=13138' },

        { id: 'tgrthaber', logo: 'https://upload.wikimedia.org/wikipedia/commons/e/e1/TGRT_Haber_logosu.png', name: 'TGRT Haber', src: 'https://www.canlitv.day/embed/?id=11' },

        { id: 'krttv', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/KRT_Logo_2023.webp/800px-KRT_Logo_2023.webp.png', name: 'KRT TV', src: 'https://www.canlitv.day/embed/?id=12377' },

        { id: 'beinsports', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/2016_beIN_Sports_logo.svg/1920px-2016_beIN_Sports_logo.svg.png', name: 'Bein Sports', src: 'https://www.canlitv.day/embed/?id=12990' },

        { id: 'trtspor', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/TRT_Spor_logo_%282022%29.svg/1920px-TRT_Spor_logo_%282022%29.svg.png', name: 'TRT Spor', src: 'https://www.canlitv.day/embed/?id=893' },

        { id: 'tlc', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/TLC_logo_%282023%29.svg/1920px-TLC_logo_%282023%29.svg.png', name: 'TLC', src: 'https://www.canlitv.day/embed/?id=11219' },

        { id: 'dmax', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/DMAX_BLACK.svg/1920px-DMAX_BLACK.svg.png', name: 'DMAX', src: 'https://www.canlitv.day/embed/?id=12979' },

        { id: 'trtbelgesel', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/TRT_Belgesel_logo_%282019-%29.svg/1920px-TRT_Belgesel_logo_%282019-%29.svg.png', name: 'TRT Belgesel', src: 'https://www.canlitv.day/embed/?id=11106' },


    ];

    const [activeTab, setActiveTab] = useState('home');

    const handleTabClick = (id) => {
        setActiveTab(id);
    };

    return (
        <div className='livetv-page-container md:px-60'>

            <div className='livetv-mid-container '>
                <h1 className='livetv-page-title1'> <i class="fa-solid fa-tv"></i> Canlı TV</h1>
                <div className='lg:grid grid-cols-8 gap-4'>
                    <div className='livetv-mid-left-container col-span-2'>
                        <h1 className='livetv-page-title2'>Kanal Listesi</h1>
                        <ul className="nav nav-underline livetv-tabs" id="channelsTab" role="tablist">
                            {channels.map((channel) => (
                                <li className="nav-item" role="presentation" key={channel.id}>
                                    <button
                                        className={`nav-link flex items-center text-neutral-600 font-semibold h-[70px] ${channel.id === activeTab ? 'active' : ''}`}
                                        id={`${channel.id}-tab`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#${channel.id}-tab-pane`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`${channel.id}-tab-pane`}
                                        aria-selected={channel.id === activeTab ? 'true' : 'false'}
                                        onClick={() => handleTabClick(channel.id)}
                                    >
                                        <img className='channel-logo-img' src={channel.logo} alt={`${channel.name} logo`} style={{ marginRight: '15px' }} />
                                        {channel.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='livetv-mid-right-container col-span-6'>



                        <div className="tab-content" id="channelsTabContent">
                            {channels.map((channel) => (
                                <div
                                    key={channel.id}
                                    className={`tab-pane fade ${channel.id === activeTab ? 'show active' : ''}`}
                                    id={`${channel.id}-tab-pane`}
                                    role="tabpanel"
                                    aria-labelledby={`${channel.id}-tab`}
                                    tabIndex="0"
                                >
                                    <h1 className='livetv-page-title2 '> {channel.name} </h1>
                                    <div className='flex justify-center'>
                                        {channel.id === activeTab && (
                                            <iframe className='livetv-iframe'
                                                title="canli tv"
                                                width="700"
                                                height="394"
                                                src={channel.src}
                                                frameBorder="0"
                                                allowFullScreen
                                            ></iframe>
                                        )}</div>
                                </div>
                            ))}
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default LiveTv;
