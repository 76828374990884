import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Carousel.css";
import resim from "../../assets/haber6.jpeg";
import fashionPic from "../../assets/fashion-styles.jpeg";
import techPic from "../../assets/tech.jpeg";
import businessPic from "../../assets/hava-durumu-a02.jpg";
import camPic from "../../assets/Camera-Functions-Header.jpeg";
import newsOne from "../../assets/haber1.jpeg";
import newsTwo from "../../assets/haber2.jpeg";
import newsThree from "../../assets/haber3.jpeg";
import newsFour from "../../assets/haber4.jpeg";
import newsFive from "../../assets/haber5.jpeg";
import artPic from "../../assets/art.jpg";
import sportPic from "../../assets/sports.jpg";
import scenePic from "../../assets/scene.jpeg";
import financePic from "../../assets/finans.jpeg";
import criptoPic from "../../assets/cripto.jpeg";

// API URL'nizi burada güncelleyin
const API_BASE_URL = "http://localhost:3000/news";

const HpBannerBox = () => {
  const [slidesData, setSlidesData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slidesData.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slidesData.length - 1 : prevSlide - 1
    );
  };

  useEffect(() => {
    const fetchSlidesData = async () => {
      try {
        const response = await axios.get(API_BASE_URL);
        console.log("API response:", response.data);

        // Slider verilerini filtreleme
        const slides = response.data.filter((news) => news.isFullWidthHeadline);
        setSlidesData(slides);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Veriler alınırken bir hata oluştu.");
        setLoading(false);
      }
    };

    fetchSlidesData();

    const interval = setInterval(nextSlide, 7000); // 7 saniye aralıklarla otomatik geçiş
    return () => clearInterval(interval); // Temizleme işlemi
  }, []);

  if (loading) return <div>Yükleniyor...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="hp">
      <div className="container w-full h-full">
        <div className="hp_banner_box">
          <div className="hp_banner_left">
            <div className="slider">
              <div className="slider-container">
                {slidesData.map((slide, index) => (
                  <div
                    className={
                      index === currentSlide ? "slide active" : "slide"
                    }
                    key={slide._id}
                    style={{ backgroundImage: `url(${slide.image})` }}
                  >
                    <a
                      href={slide.slug ? `/news/${slide.slug}` : "#"}
                      className="slideImg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={slide.image} alt={`Slide ${slide._id}`} />
                      <div
                        className="slide-title"
                        style={{ display: slide.isBreakingNews ? "block" : "none" }}
                      >
                        <h3 className="slideSondk">
                          {slide.isBreakingNews ? "SON DAKİKA" : ""}
                        </h3>
                      </div>
                      <div className="slide-content">
                        <p className="text-left">{slide.title}</p>
                      </div>
                    </a>
                  </div>
                ))}
                <button className="prev" onClick={prevSlide}>
                  &#10094;
                </button>
                <button className="next" onClick={nextSlide}>
                  &#10095;
                </button>
              </div>
              <div className="numbers">
                {slidesData.map((slide, index) => (
                  <span
                    key={slide._id}
                    className={
                      index === currentSlide ? "number active-number" : "number"
                    }
                    onClick={() => setCurrentSlide(index)}
                  >
                    {""}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="hp_banner_right">
            <div className="newsTitle">
              <div className="newsTop">
                <div className="imgBox">
                  <div className="overlayTwo">
                    <div className="overlay colorOrange">HAVA DURUMU</div>
                    <p className="text-left">Denizde dehşet</p>
                  </div>
                  <img src={resim} alt="Resim açıklaması" className="image" />
                </div>
                <div className="imgBox">
                  <a href="/content">
                    <div className="overlayTwo">
                      <div className="overlay colorGreen">DÖVİZ</div>
                      <p className="text-left">Altında son durum</p>
                    </div>
                    <img
                      src={financePic}
                      alt="Resim açıklaması"
                      className="image"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="downLinks">
          <div className="row w-100">
            <div className="col-4">
              <div className="imgBox">
                <div className="overlayTwo">
                  <div className="overlay colorSky">HAVA DURUMU</div>
                  <p className="text-left">Meteoroloji’den yağış uyarısı</p>
                </div>
                <img
                  src={businessPic}
                  alt="Resim açıklaması"
                  className="image"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="imgBox">
                <div className="overlayTwo">
                  <div className="overlay colorRed">GÜNDEM</div>
                  <p className="text-left">Kara yollarında durum</p>
                </div>
                <img
                  src={fashionPic}
                  alt="Resim açıklaması"
                  className="image"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="imgBox">
                <div className="overlayTwo">
                  <div className="overlay colorLightGreen">DÖVİZ</div>
                  <p className="text-left">Dolar ve euro güne nasıl başladı?</p>
                </div>
                <img src={artPic} alt="Resim açıklaması" className="image" />
              </div>
            </div>
          </div>
          <div className="row w-100">
            <div className="col-4">
              <div className="imgBox">
                <div className="overlayTwo">
                  <div className="overlay colorGreen">ENERJİ</div>
                  <p className="text-left">Günlük elektrik verileri</p>
                </div>
                <img src={scenePic} alt="Resim açıklaması" className="image" />
              </div>
            </div>
            <div className="col-4">
              <div className="imgBox">
                <div className="overlayTwo">
                  <div className="overlay colorOrange">SPOR</div>
                  <p className="text-left">Takımların yeni transferleri</p>
                </div>
                <img src={sportPic} alt="Resim açıklaması" className="image" />
              </div>
            </div>
            <div className="col-4">
              <div className="imgBox">
                <div className="overlayTwo">
                  <div className="overlay colorSky">HAVA DURUMU</div>
                  <p className="text-left">Son dakika gelişmeleri</p>
                </div>
                <img src={camPic} alt="Resim açıklaması" className="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HpBannerBox;
