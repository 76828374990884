import React, { useState } from "react";
import "./most-read-comment.css";

const mainCard = {
  image:
    "https://ekonomimanset.com/wp-content/uploads/2022/10/image_750x_618eaf5f0cab5-14.jpg",
  text: "Kripto parada şok! İsveç’ten yasak çağrısı",
};

const subCards = [
  {
    image:
      "https://ekonomimanset.com/wp-content/uploads/2022/10/image_750x_61668916be41e.jpg",
    text: "Bitcoin 2017’den sonra ilk güncellemesine zayıf fiyatlarla gidiyor",
  },
  {
    image:
      "https://ekonomimanset.com/wp-content/uploads/2022/10/image_750x_616561415ae23-10.jpg",
    text: "Shiba Inu balinası altcoin toplamaya başladı",
  },
  {
    image:
      "https://ekonomimanset.com/wp-content/uploads/2022/10/image_750x_618eaf5f0cab5-20.jpg",
    text: "Kripto para piyasaları ağır yara aldı",
  },
  {
    image:
      "https://ekonomimanset.com/wp-content/uploads/2022/11/Sam-Bankman-1024x576.jpg",
    text: "Sam Bankman-Fried’ın mal varlığı tehlikede",
  },
  {
    image:
      "https://ekonomimanset.com/wp-content/uploads/2023/11/BITCOIN-260x160.jpg",
    text: "Bitcoin 62.000 doları geçmeye çalışıyor",
  },
  {
    image:
      "https://ekonomimanset.com/wp-content/uploads/2023/11/BITCOIN-260x160.jpg",
    text: "Bitcoin ETF’lerinde bir hafta sonra ilk!",
  },
  {
    image:
      "https://ekonomimanset.com/wp-content/uploads/2024/06/Senol-Vatansever-BIDER-Baskani-W2E.MEDIA-CEO-260x160.jpg",
    text: "Kripto varlık ve teknoloji startuplarından vergi alınmalı mı?",
  },
  {
    image:
      "https://ekonomimanset.com/wp-content/uploads/2024/06/1-7-260x160.png",
    text: "“Regülasyonlar birleşme ve satın almaları beraberinde getirebilir”",
  },
];

const MostRead = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [fade, setFade] = useState(true);
  const itemsPerPage = 4;
  const totalPages = Math.ceil(subCards.length / itemsPerPage);

  const handlePrev = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentPage((prevPage) =>
        prevPage > 0 ? prevPage - 1 : totalPages - 1
      );
      setFade(true);
    }, 500);
  };

  const handleNext = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentPage((prevPage) =>
        prevPage < totalPages - 1 ? prevPage + 1 : 0
      );
      setFade(true);
    }, 500);
  };

  const startIndex = currentPage * itemsPerPage;
  const currentSubCards = subCards.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="flex flex-row mt-3 category-page-slider-cards-content">
      <div className="category-page-slider-card">
      <h1 className='category-page-title1'>En Çok Okunanlar</h1>
        <div className="category-page-slider-main-card">
          <img src={mainCard.image} alt="Main" className="category-page-main-card-image" />
          <h2 className="category-page-main-card-text">{mainCard.text}</h2>
          {/* <button className="category-page-category-slider-btn">EN ÇOK OKUNANLAR</button> */}
        </div>
        <div className={`sub-cards ${fade ? "fade-in" : "fade-out"}`}>
          {currentSubCards.map((card, index) => (
            <div className="category-page-sub-card" key={index}>
              <img src={card.image} alt="Sub" className="category-page-sub-card-image" />
              <div className="category-page-sub-card-text">{card.text}</div>
            </div>
          ))}
        </div>
        <div className="navigation">
          <span className="nav-button-left" onClick={handlePrev}>
            <i className="fa-solid fa-chevron-left"></i>
          </span>
          <span className="nav-button-right" onClick={handleNext}>
            <i className="fa-solid fa-chevron-right"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default MostRead;
